import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getImage } from '../../helper'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import { RELATED_CHANNEL } from '../../apiUrls'
import useAxios from 'axios-hooks'
import InComponentLoader from '../../components/InComponentLoader'

const ChannelRelated = ({ theme, channelId, onChannelClick }) => {
  const { isCustomTemplate, accountObj } = useContext(AppContext)
  let navigate = useNavigate()

  const [{ data: relatedChannel, loading: relatedChannelLoading }] = useAxios({
    url: `${RELATED_CHANNEL}${channelId}`,
    method: 'post',
    data: {
      filters: {},
    },
  })

  const handleRedirect = (id) => {
    onChannelClick()
    navigate(`/channel/${id}`)
  }

  return (
    <>
      <InComponentLoader loading={relatedChannelLoading} />
      <div
        className="mt-10 flex flex-wrap md:gap-x-[3%] sm:gap-x-[5%] sm:px-0 px-3"
        style={{ rowGap: '3vw' }}
      >
        {relatedChannel &&
          !isEmpty(relatedChannel?.data) &&
          relatedChannel.data.map((channel, index) => (
            <div
              className="playlist rounded-lg md:w-[17.25%] sm:w-[47%] w-full sm:block flex cursor-pointer"
              style={{
                aspectRatio: window.matchMedia('(max-width: 640px)').matches ? 'unset' : '302/231',
              }}
              key={channel.channel_id}
              onClick={() => handleRedirect(channel.channel_id)}
            >
              <div className="sm:w-full relative w-2/5">
                <img
                  src={getImage(accountObj?.cf_domain_name, channel?.thumbnail)}
                  className="w-full object-cover rounded-lg"
                  alt=""
                  style={{ aspectRatio: '302/169' }}
                />
              </div>
              <div className="text-white sm:w-auto w-3/5 sm:pl-0 pl-2">
                <h1 className="font-bold lg:text-[1.1vw] text-base">{channel.name}</h1>
              </div>
            </div>
          ))}

        {relatedChannel && isEmpty(relatedChannel.data) && !relatedChannelLoading && (
          <p
            className={'text-md md:text-md font-bold mt-5 '}
            style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
          >
            No related channels available
          </p>
        )}
      </div>
      {relatedChannel && !isEmpty(relatedChannel.data) && relatedChannel.data.length > 4 && (
        <div className={'flex justify-center mt-5'}>
          <button
            className="px-4 py-1 ml-2 md:text-sm"
            style={{
              color: '#fff',
              backgroundColor: '#1976d2',
              borderRadius: '6px',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              navigate(`/channels`)
            }}
          >
            {`View all Channels`}
          </button>
        </div>
      )}
    </>
  )
}

export default withTheme(ChannelRelated)
