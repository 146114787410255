import React, { useState } from 'react'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import DeleteModal from '../../DeleteModal/DeleteModal'

const BobbingCards = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { customNavigate, EditIcon } = useTemplateHook()
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedCardIndex, setSelectedCardIndex] = useState('')

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const deleteCard = (index) => {
    setSelectedCardIndex(index)
    setDeleteModal(true)
  }

  const handleModal = (cardInfo, type, cardIndex, isEdit) => {
    setModalState({
      keyID: id,
      modalData: cardInfo,
      type: type,
      cardIndex: cardIndex,
      isEdit,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const onDeleteCard = () => {
    let tempdata = data
    tempdata?.card_data.splice(selectedCardIndex, 1)
    modifyData(id, tempdata)
    setDeleteModal(false)
    setSelectedCardIndex('')
  }

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

    return (
      <button
        className={` absolute left-[3vw] top-1/2 z-10  border-0 bg-transparent px-0 hidden lg:block`}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        title="Previous Item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <path
            d="m9 6 11.928 11.928L9 29.856"
            transform="rotate(180 11.171 15.282)"
            style={{
              fill: 'none',
              stroke: theme.c4,
              strokeWidth: 2,
            }}
          />
        </svg>
      </button>
    )
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

    return (
      <button
        className={` absolute right-[2.25vw] top-1/2 z-10 border-0 bg-transparent px-0 hidden lg:block`}
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        title="Next Item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <path
            d="m9 6 11.928 11.928L9 29.856"
            transform="translate(-8.293 -5.293)"
            style={{
              fill: 'none',
              stroke: theme.c4,
              strokeWidth: 2,
            }}
          />
        </svg>
      </button>
    )
  }

  return (
    <div
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c1, color: theme.c4 }}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="2xl:py-14 xl:py-10 py-8">
        {data?.title && (
          <h2
            className={`xl:text-3xl md:text-xl text-xs font-bold sm:mb-[2vw] mb-6 2xl:mx-32 xl:mx-24 md:mx-16 sm:mx-10 mx-[10px] relative ${
              isEdit && hoverState === 'HEADING' && 'component-hover-class pr-10'
            } w-fit`}
            onMouseOver={() => handleHoverItems('HEADING')}
            onMouseLeave={() => handleHoverItems('TOP')}
            style={{ color: theme.c2 }}
          >
            {data.title}
            {hoverState === 'HEADING' && (
              <div
                className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 -right-3 flex justify-center items-center cursor-pointer"
                onClick={() => handleModal({ title: data.title }, 'HEADING', 0, true)}
              >
                <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
              </div>
            )}
          </h2>
        )}

        <div className="w-full text-[#fefefe]">
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            wrapperClassName="w-full md:mt-4"
          >
            {data?.card_data &&
              data?.card_data.map((info, index) => (
                <Card
                  key={index}
                  itemId={`index${index}`}
                  index={index}
                  info={info}
                  handleRedirect={handleRedirect}
                  isEdit={isEdit}
                  hoverState={hoverState}
                  theme={theme}
                  handleHoverItems={handleHoverItems}
                  handleModal={handleModal}
                  deleteCard={deleteCard}
                />
              ))}
          </ScrollMenu>

          {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}

          {deleteModal && (
            <DeleteModal
              openModal={deleteModal}
              title={`Are you sure you want to delete this Card ?`}
              confirmButtonText={`Yes`}
              onCancel={() => {
                setSelectedCardIndex('')
                setDeleteModal(false)
              }}
              onConfirm={onDeleteCard}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function Card({
  index,
  info,
  handleRedirect,
  isEdit,
  hoverState,
  theme,
  handleHoverItems,
  handleModal,
  deleteCard,
}) {
  const { getTemplateImage, PlusIcon, EditIcon, TrashIcon } = useTemplateHook()
  return (
    <div key={index} className="flex">
      <div
        className={`${
          !index && 'ml-2 2xl:ml-32 xl:ml-24 md:ml-16 sm:ml-10'
        } 2xl:w-[305px] lg:w-[265px] md:w-[200px] sm:w-[170px] w-[139px] 2xl:h-[230px] lg:h-[200px] md:h-[150px] sm:h-[129px] h-[108px] ${
          !isEdit && 'sm:mr-[2.25vw] mr-6'
        } `}
        style={{ color: theme.c4 }}
        onClick={() => handleRedirect(info?.link)}
        tabIndex={0}
      >
        <div
          className={`relative h-full w-full flex ${
            info.is_heading_up ? 'flex-col-reverse' : 'flex-col'
          }  justify-between items-center cursor-pointer ${
            isEdit && hoverState === `CARD${index}` && 'component-hover-class p-1'
          }`}
          onMouseOver={() => handleHoverItems(`CARD${index}`)}
          onMouseLeave={() => handleHoverItems('TOP')}
        >
          {hoverState === `CARD${index}` && (
            <div
              className={`absolute w-full top-2 right-1
          flex justify-end items-center z-10`}
            >
              <div
                className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                onClick={() => handleModal(info, 'CARD', index, true)}
              >
                <img src={EditIcon} className="w-[17px] h-[17px]" alt="editicon" />
              </div>
              <div
                className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                onClick={() => deleteCard(index)}
              >
                <img src={TrashIcon} className="w-[17px] h-[17px]" alt="trashicon" />
              </div>
            </div>
          )}
          <CustomImage
            imageUrl={getTemplateImage(info?.image_s3key)}
            imgClass="z-0 w-full h-auto border"
            loaderClass="z-0 w-full h-2/3"
            loaderStyle={{
              background: '#5A5A5A50',
            }}
            imgAlt={'class img"'}
          />
          <p
            className="md:py-[8px] sm:py-[6px] py-[4px] md:px-[16px] sm:px-[10px] px-[4px] font-semibold w-full 2xl:text-lg xl:text-base lg:text-sm md:text-xs text-[10px]"
            style={{ backgroundColor: theme.c3, color: theme.c7, marginBottom: '0' }}
          >
            {info?.heading}
          </p>
        </div>
      </div>
      <div
        className={`${
          isEdit ? '' : 'hidden'
        } rosemary-toptrending-add-button d-flex justify-content-center align-items-center  py-1 sm:mx-[1.125vw] `}
      >
        <div style={{ border: '1px solid #2172eb' }} className="relative h-full">
          <div
            onClick={() => handleModal(info, 'CARD', index, false)}
            className="cursor-pointer absolute h-[32px] w-[32px] rounded-full bg-[#2172eb] top-[45%] -left-4 flex justify-center items-center"
          >
            <img src={PlusIcon} className="w-[17px] h-[17px]" alt="plus icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(BobbingCards)
