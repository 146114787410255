import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { GET_TRANSACTION } from '../../apiUrls'
import { AppContext } from '../../context/app'
import { getReleaseDate } from '../../helper'
import getSymbolFromCurrency from 'currency-symbol-map'
import { isEmpty } from 'lodash'
import { SubscriptionContext } from '../../context/subscription'
import moment from 'moment'
import ViewTransactionDetailModal from './viewTransactionDetailModal'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const MyTransactions = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const { setOpenSubscriptionModal } = useContext(SubscriptionContext)
  const { t } = useTranslation()
  const [detailShow, setDetailShow] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [transactions, setTransactions] = useState([])

  const [{ data: transactionData, loading: transactionDataLoading }, transactionDataCall] =
    useAxios(
      {
        url: GET_TRANSACTION,
        method: 'get',
      },
      { manual: true },
    )

  useEffect(() => {
    transactionDataCall()
  }, [])

  useEffect(() => {
    setLoader(transactionDataLoading)
  }, [transactionDataLoading])

  useEffect(() => {
    if (transactionData && transactionData.success) {
      setTransactions(transactionData.data)
    }
  }, [transactionData])

  return (
    <div className="min-h-full md:py-8">
      <div className={`text-center xl:mx-20 xl:text-left`}>
        <p
          className="underline text-white text-md lg:text-2xl mb-2 "
          style={{ textTransform: 'capitalize', color: isCustomTemplate ? theme.c3 : '#fff' }}
        >
          {t('my_transactions')}
        </p>
      </div>
      {!isEmpty(transactions) ? (
        transactions.map((info, index) => (
          <div className=" flex justify-center py-2 items-center" key={index}>
            <div
              style={{
                backgroundColor: isCustomTemplate ? theme.c10 : '#151d27',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(55,65,81)',
              }}
              className={`p-4 2xl:h-30 w-11/12 xl:w-3/5 shadow rounded-md relative my-1 border `}
            >
              <div className="md:grid md:grid-cols-12 gap-2">
                <div
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" flex flex-col md:col-span-4 "
                >
                  <p className="md:text-lg xl:text-2xl">{info.subscription_name}</p>
                </div>
                <div
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="md:flex  justify-between col-span-8 "
                >
                  <div>
                    <p className="">
                      {t('validity')} : {getReleaseDate(info.start_date)} {t('to')}{' '}
                      {getReleaseDate(info.end_date)}
                    </p>
                    {info.status && (
                      <p className="xl:py-2">
                        {t('ends_in')} : {moment(info.end_date).diff(Date.now(), 'days') + 1}{' '}
                        {t('days')}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <p
                      className={`md:text-lg xl:text-xl flex ${
                        !info.status ? 'xl:mr-3' : 'xl:mr-1'
                      }`}
                    >
                      {t('status')} :
                      <p className={`px-2 ${info.status ? 'text-green-500' : 'text-red-600'}`}>
                        {info.status ? t('active') : t('expired')}
                      </p>
                    </p>
                    <p className={`md:mt-1 md:text-lg ${!info.status && 'py-2'}`}>
                      {t('amount')} : {getSymbolFromCurrency(info.currency)}{' '}
                      {parseFloat(info?.transaction_amount).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-end ">
                <p
                  style={{ color: isCustomTemplate ? theme.c5 : 'rgb(96,165,250)' }}
                  className="cursor-pointer hover:underline"
                  onClick={() => {
                    setSelectedTransaction(info)
                    setDetailShow(true)
                  }}
                >
                  {t(`view_details`)}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className="h-[60vh] flex flex-col justify-center py-2 items-center "
        >
          <p className="md:text-lg xl:text-xl">{t('no_transactions_found')} !!</p>
          <button
            className="mt-4 md:mt-10 px-4 py-1 focus:outline-none"
            style={{ color: '#fff', backgroundColor: '#1976d2', borderRadius: '6px' }}
            onClick={() => setOpenSubscriptionModal(true)}
          >
            {t('purchase')}
          </button>
        </div>
      )}
      {detailShow && (
        <ViewTransactionDetailModal
          onCancel={() => {
            setSelectedTransaction({})
            setDetailShow(false)
          }}
          data={selectedTransaction}
        />
      )}
    </div>
  )
}

export default withTheme(MyTransactions)
