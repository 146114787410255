import { isEmpty } from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { CgProfile } from 'react-icons/cg'
import { GrEmoji } from 'react-icons/gr'
import { FaRegKeyboard } from 'react-icons/fa'
import { MdOutlineSend } from 'react-icons/md'
import ScrollToBottom from 'react-scroll-to-bottom'
import { useTranslation } from 'react-i18next'
import Picker from 'emoji-picker-react'
import { AppContext } from '../../context/app'
import { LIVE_COMMENTS_API } from '../../apiUrls'
import useAxios from 'axios-hooks'
import { useParams } from 'react-router'
import { withTheme } from 'theming'
import imageSpinner from '../../assets/image-spinner.gif'

let commentTimestamp = ''
const StreamComments = ({ theme }) => {
  const [chatStream, setChatStream] = useState([])
  const [inputText, setInputText] = useState('')
  const [localLoader, setLocalLoader] = useState(false)
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { isLogin, setOpenLoginModal, isCustomTemplate } = useContext(AppContext)
  const contentId = useParams().contentId

  const { t } = useTranslation()

  const [{ data: commentsList, loading: commentsListLoading }, getLiveComments] = useAxios(
    {
      url: LIVE_COMMENTS_API + '/' + contentId,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: postCommentLoading }, postCommentCall] = useAxios(
    {
      url: LIVE_COMMENTS_API + '/' + contentId,
      method: 'post',
    },
    { manual: true },
  )
  useEffect(() => {
    setLocalLoader(postCommentLoading)
  }, [postCommentLoading])

  useEffect(() => {
    if (commentsList && commentsList?.success) {
      setChatStream((chatStream) => chatStream.concat(commentsList?.data))

      commentTimestamp =
        commentsList?.data?.length > 0
          ? commentsList?.data[commentsList?.data?.length - 1]?.timestamp
          : commentTimestamp
    }
  }, [commentsList])

  useEffect(() => {
    if (isEmpty(chatStream)) {
      getChatData().then((r) => console.log(r))
    }
  }, [])

  const getChatData = async () => {
    await getLiveComments()

    setInterval(async () => {
      await getLiveComments({
        params: {
          timestamp: commentTimestamp,
        },
      })
    }, 10000)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputText) {
      handleSubmit().then((r) => console.log(r))
    }
  }

  const handleSubmit = async () => {
    if (!isLogin) {
      setOpenLoginModal(true)
      setInputText('')
      return
    }
    if (inputText) {
      const postRes = await postCommentCall({
        data: {
          comment: inputText,
        },
      })
      if (postRes.data?.success) {
        setInputText('')
        window.scrollTo(0, 0)
        await getLiveComments({
          params: {
            timestamp: commentTimestamp,
          },
        })
      }
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setInputText((inputText) => inputText.concat(emojiObject.emoji))
  }

  return (
    <div
      style={{
        backgroundColor: isCustomTemplate ? theme.c10 : 'rgb(27, 36, 52)',
        borderColor: isCustomTemplate ? theme.c4 : 'rgb(107,114,128)',
      }}
      className={` w-full h-[40vh] xl:h-full border  relative`}
    >
      <div
        className={`absolute w-full h-full top-0 flex items-center justify-center z-99 ${
          localLoader ? 'visible' : 'hidden'
        }`}
        style={{ backgroundColor: 'rgba(60, 60, 60, 0.6)' }}
      >
        <img src={imageSpinner} alt="spinner" style={{ width: '2rem' }} />
      </div>
      <div className=" flex justify-between items-center px-3 xl:px-5 py-2 ">
        <p
          style={{ color: isCustomTemplate ? theme.c2 : '#fff' }}
          className="text-sm md:text-xl xl:text-2xl font-semibold"
        >
          {t('live_comment')}
        </p>

        {commentsListLoading && <p className="text-gray-500 text-xs italic">Fetching...</p>}
      </div>
      <div
        style={{
          backgroundColor: isCustomTemplate ? theme.c10 : '#1b242f',
          position: 'absolute',
          top: '45px',
        }}
        className={`px-3 xl:px-5 w-full ${
          emojiPickerOpen
            ? `bottom-[212px] md:bottom-[270px] lg:bottom-[320px] xl:bottom-[330px] `
            : ' bottom-[55px] md:bottom-[70px]'
        }`}
      >
        <ScrollToBottom className="w-full h-full" scrollViewClassName="scrollbar-class">
          {!isEmpty(chatStream) ? (
            chatStream.map((info, index) => (
              <div className={'my-2 md:my-4 '} key={index}>
                <div className="flex align-middle items-center">
                  <CgProfile
                    style={{ color: isCustomTemplate ? theme.c5 : '#e3e2e2', opacity: '0.85' }}
                    className="w-4 h-4"
                  />
                  <span
                    style={{ color: isCustomTemplate ? theme.c5 : 'rgb(229,231,235)' }}
                    className={'text-xs md:text-sm ml-2 '}
                  >
                    {info.user.name}
                  </span>
                </div>
                <div
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="mt-1 text-sm md:text-base"
                >
                  <p> {info.comment}</p>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
              className="h-full w-full flex justify-center items-center"
            >
              {t('no_comments')}
            </div>
          )}
        </ScrollToBottom>
      </div>
      <div
        style={{ borderTop: '0.5px solid gray' }}
        className=" w-full mt-2 px-3 xl:px-5 py-2 md:py-3 xl:py-4 absolute bottom-0"
      >
        <div className=" w-full">
          <div className="relative flex">
            <span className=" inset-y-0 flex items-center mt-2">
              {emojiPickerOpen ? (
                <FaRegKeyboard
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                  onClick={() => setEmojiPickerOpen(false)}
                />
              ) : (
                <GrEmoji
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                  onClick={() => setEmojiPickerOpen(true)}
                />
              )}
            </span>

            <input
              type="text"
              placeholder={`${t('say_something')}...`}
              style={{
                color: isCustomTemplate ? theme.c4 : '#fff',
                borderColor: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="w-full text-sm md:text-base focus:outline-none bg-transparent mx-2 border-b "
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span className="inset-y-0 flex items-center mt-2">
              <MdOutlineSend
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="h-6 w-6 cursor-pointer"
                onClick={handleSubmit}
              />
            </span>
          </div>
        </div>
        {emojiPickerOpen && (
          <div className={`h-[150px] md:h-[200px] lg:h-[250px] xl:h-[250px]`}>
            <Picker
              pickerStyle={{
                width: '100%',
                height: '100%',
                marginTop: '10px',
                boxShadow: 'none',
                borderRadius: '3px',
              }}
              onEmojiClick={onEmojiClick}
              disableSearchBar={true}
              groupNames={{
                smileys_people: t('people_emoji'),
                animals_nature: t('animals_emoji'),
                food_drink: t('food_emoji'),
                travel_places: t('travel_emoji'),
                activities: t('activities_emoji'),
                objects: t('objects_emoji'),
                symbols: t('symbols_emoji'),
                flags: t('flags_emoji'),
                recently_used: t('recently_used'),
              }}
              disableAutoFocus={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default withTheme(StreamComments)
