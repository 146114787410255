import React, { useState } from 'react'
import imageSpinner from '../../assets/image-spinner.gif'
import BasicPlaceholder from '../../assets/basic-placehoder.jpg'

const CustomImage = ({
  imageUrl,
  imgStyle = {},
  imgClass = '',
  imgAlt = 'placehoder',
  errorPlaceholder = BasicPlaceholder,
  loaderClass = '',
  loaderStyle = {},
}) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  const imageStyle = !imgLoaded ? { display: 'none' } : { ...imgStyle }
  return (
    <>
      {!imgLoaded && (
        <div style={loaderStyle} className={`flex items-center justify-center ${loaderClass} `}>
          <img src={imageSpinner} alt="spinner" style={{ width: '2rem' }} />
        </div>
      )}
      <img
        src={imageUrl}
        style={imageStyle}
        className={imgClass}
        alt={imgAlt}
        onLoad={() => setImgLoaded(true)}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = errorPlaceholder
        }}
      />
    </>
  )
}

export default CustomImage
