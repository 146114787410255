import React, { useState } from 'react'
import { isEmpty } from '../../helper'

const menuBtnStyles = {
  fontSize: 'max(1.2vw,16px)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  //   background: 'gray',
  paddingBottom: '10px',
  borderBottom: '3px solid',
}

const PlaylistBottom = ({ options = [] }) => {
  const [menuState, setMenuState] = useState(0)

  const renderComponent = (num) => {
    return options[num]?.child
  }
  return (
    <div className="xl:mt-[-10vh] lg:mt-[-8vh]">
      <div
        className=" 2xl:px-24 xl:px-14 md:px-10 px-4 xl:h-[10vh] lg:h-[8vh] h-[7vh] w-full"
        style={{
          background:
            'linear-gradient(to bottom, rgba(27, 36, 47, 0), rgba(27, 36, 47, 0.73) 60%, #1b242f)',
          position: 'relative',
          zIndex: '10',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #707070',
            overflowX: 'auto',
          }}
          className="relative h-full"
        >
          <div className="flex justify-start items-end h-full md:gap-x-[min(5vw,71px)] gap-x-[43px] md:w-full w-[500px]">
            {!isEmpty(options) &&
              options.map((option, idx) => (
                <button
                  key={idx}
                  style={{
                    ...menuBtnStyles,
                    color: menuState === idx ? '#09e9e9' : '#cecece',
                    borderBottomColor: menuState === idx ? '#09e9e9' : 'transparent',
                  }}
                  onClick={() => setMenuState(idx)}
                >
                  {option.title}
                </button>
              ))}
          </div>
        </div>
      </div>
      <div
        style={{ minHeight: '48vh', border: '2px solid red', borderColor: 'transparent' }}
        className="w-full relative text-white 2xl:px-24 xl:px-14 md:px-10 px-4"
      >
        {renderComponent(menuState)}
      </div>
    </div>
  )
}

export default PlaylistBottom
