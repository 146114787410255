import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import ContentListComponent from '../contentListing/contentListComponent'
import { WATCHLIST_URL } from '../../apiUrls'
import { AppContext } from '../../context/app'
import { ContentContext } from '../../context/content'
import { useTranslation } from 'react-i18next'

const Watchlist = () => {
  const { t } = useTranslation()
  const title = t('watchlist')
  const { setLoader } = useContext(AppContext)
  const [dataList, setDataList] = useState([])
  const { isRequestDone } = useContext(ContentContext)

  const [{ data: contentList, loading: contentListLoading }, watchListCall] = useAxios({
    url: WATCHLIST_URL,
    method: 'get',
  })

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList(contentList.data)
    }
  }, [contentList])

  useEffect(() => {
    if (isRequestDone) {
      watchListCall()
    }
  }, [isRequestDone])

  useEffect(() => {
    setLoader(contentListLoading)
  }, [contentListLoading])

  return <ContentListComponent title={title} data={dataList} noContentText={t('watchlist_empty')} />
}

export default Watchlist
