import React, { createContext, useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { AppContext } from './app'
import { TEMPLATE_V2_URL } from '../apiUrls'
import defaultTheme from '../components/themes/defaultTheme'
import magentaHighlight from '../components/themes/magentaHighlight'
import neonDark from '../components/themes/neonDark'
import pantoneRed from '../components/themes/pantoneRed'
import darkInfraRed from '../components/themes/darkInfraRed'
import greenEnergy from '../components/themes/greenEnergy'
import { isEmpty } from 'lodash'

const themes = [defaultTheme, magentaHighlight, neonDark, pantoneRed, darkInfraRed, greenEnergy]

const initialState = {
  templateData: {},
  componentData: [],
  themeIndex: 0,
}

export const TemplateContext = createContext(initialState)

const TemplateProvider = (props) => {
  const [templateData, setTemplateData] = useState({})
  const [componentData, setComponentData] = useState([])
  const [themeIndex, setThemeIndex] = useState(0)
  const [templateLoader, setTemplateLoader] = useState(false)
  const { setLoader, isCustomTemplate } = useContext(AppContext)

  const [{ data: templateList, loading: templateListLoading }, templateListCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (isCustomTemplate) {
      templateListCall()
    }
  }, [isCustomTemplate])

  useEffect(() => {
    setLoader(templateListLoading)
    setTemplateLoader(templateListLoading)
  }, [templateListLoading])

  useEffect(() => {
    if (isCustomTemplate && templateList && templateList.success) {
      let tempData = templateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]
        setTemplateData(particularTemplateData)
        setComponentData(particularTemplateData?.components)
        if (!isEmpty(particularTemplateData)) {
          let tempThemeIndex = themes.findIndex(
            (info) => info.name === particularTemplateData?.selected_theme?.name,
          )
          if (tempThemeIndex > -1) {
            setThemeIndex(tempThemeIndex)
          }
        }
      }
    }
  }, [templateList])

  return (
    <div style={{ backgroundColor: themes[themeIndex].c1 }}>
      <TemplateContext.Provider
        value={{
          templateData,
          componentData,
          themeIndex,
          templateLoader,
        }}
      >
        {props.children}
      </TemplateContext.Provider>
    </div>
  )
}

export default TemplateProvider
