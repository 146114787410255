import React, { useContext } from 'react'
import { getImage } from '../../helper'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'
import CustomImage from '../../components/customImage/customImage'
import VenuePlaceholder from '../../assets/venue-placeholder.png'
import { withTheme } from 'theming'

const VenueCard = ({ item, theme }) => {
  const { accountObj, isCustomTemplate } = useContext(AppContext)
  const navigate = useNavigate()

  const handleDetail = (item) => {
    navigate(`/venue/detail/${item._id}`)
  }

  return (
    <div
      style={{ borderColor: isCustomTemplate ? theme.c4 : '' }}
      className={`shadow my-1 w-full border relative h-24 md:h-32 lg:h-44 2xl:h-[18.5rem] cursor-pointer bg-black flex flex-col justify-center`}
      onClick={() => handleDetail(item)}
    >
      <CustomImage
        imageUrl={getImage(accountObj?.cf_domain_name, item?.thumbnail)}
        imgClass="w-full object-contain h-full"
        loaderClass={'h-32'}
        imgAlt={item.name}
        errorPlaceholder={VenuePlaceholder}
      />
      <div
        style={{ color: isCustomTemplate ? theme.c7 : '#fff' }}
        className=" bg-gradient-to-r from-[#d20c83]/75 to-[#0dedf5]/75 bg-opacity-10 w-full absolute bottom-0"
      >
        <p className="w-ful text-xs sm:text-sm font-semibold h-1/2 my-3 px-2 line-clamp-1">
          {item.name}
        </p>
        {/*<div className="flex items-center px-2">*/}
        {/*  {item.views_count !== 0 && <BsEye />}*/}
        {/*  {item.views_count !== 0 && (*/}
        {/*    <p className=" text-xs md:text-sm px-1">*/}
        {/*      {viewsFormatter(item.views_count)} views*/}
        {/*    </p>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default withTheme(VenueCard)
