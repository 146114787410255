import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import PlaylistPlayer from '../player/playlistPlayer'
import LoopIcon from '../../assets/loop-icon.svg'
import ShuffleIcon from '../../assets/shuffle-icon.svg'
import PlayIcon from '../../assets/playlist-play-icon.svg'
import PauseIcon from '../../assets/playlist-pause-icon.svg'
import LockIcon from '../../assets/lock-icon.svg'
import {
  CONTENT_FILTERING_V2,
  CONTENT_WATCH_URL,
  DETAIL_CONTENT,
  GET_ACTIVE_SUBSCRIPTIONS,
  RELATED_CONTENT,
} from '../../apiUrls'
import useAxios from 'axios-hooks'
import { convertDuration, getImage, getRandomWithOneExclusion, getReleaseDate } from '../../helper'
import { AppContext } from '../../context/app'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import CustomImage from '../../components/customImage/customImage'
import ReviewsListing from '../../components/reviewRating'
import CommentListing from '../../components/comments'
import PlaylistBottom from './playlistBottom'
import PlaylistAbout from './playlistAboutus'
import PlaylistRelated from './relatedPlaylist'
import { SubscriptionContext } from '../../context/subscription'

let isShuffleGlobal = false
let currentIndexGlobal = 0
const PlaylistHome = (theme) => {
  const playlistId = useParams().playlistId
  const { isLogin, setLoader, setOpenLoginModal, setModalType, accountObj } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const [isShuffle, setIsShuffle] = useState(false)
  const [detailObj, setDetailObj] = useState({})
  const [playListContents, setPlayListContents] = useState([])
  const [currentPlayingContent, setCurrentPlayingContent] = useState({})
  const [videoSource, setVideoSource] = useState(null)
  const [isPlaylistSubscribed, setIsPlaylistSubscribed] = useState(true)
  const [processingLoader, setProcessingLoader] = useState(false)

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: relatedVideos, loading: relatedVideosLoading }] = useAxios({
    url: `${RELATED_CONTENT}${playlistId}`,
    method: 'post',
    data: {
      filters: {
        video_types: ['PLAYLIST'],
      },
    },
  })

  const [{ data: playlistDetail, loading: playlistLoading }, playlistDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${playlistId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: playlistcontentLoading }, playlistcontentCall] = useAxios(
    {
      url: CONTENT_FILTERING_V2,
      method: 'post',
    },
    { manual: true },
  )

  const [{ loading: activeSubLoading }, activeSubCall] = useAxios(
    {
      url: GET_ACTIVE_SUBSCRIPTIONS,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    playlistDetailCall()
    setProcessingLoader(true)
  }, [playlistId])

  useEffect(() => {
    setLoader(
      relatedVideosLoading ||
        contentWatchUrlLoading ||
        playlistLoading ||
        playlistcontentLoading ||
        activeSubLoading ||
        processingLoader,
    )
  }, [
    relatedVideosLoading,
    contentWatchUrlLoading,
    playlistLoading,
    activeSubLoading,
    playlistcontentLoading,
    processingLoader,
  ])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setVideoSource(contentWatchUrl?.data?.signed_url)
    }
  }, [contentWatchUrl])

  useEffect(async () => {
    if (playlistDetail && playlistDetail.success) {
      const contentObj = playlistDetail.data
      setDetailObj(contentObj)
      let playloadObj =
        contentObj?.playlist_data?.playlist_type === 'MANUAL'
          ? {
              filters: {
                content_ids: contentObj?.playlist_data?.content_data,
                video_types: ['SPV', 'MPVC'],
              },
            }
          : {
              filters: {
                system_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'system_defined',
                ),
                custom_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'custom_defined',
                ),
                video_types: ['SPV', 'MPVC'],
              },
              order_by: 'modified_date',
              reverse: false,
            }

      let playlistRes = await playlistcontentCall({
        data: {
          ...playloadObj,
          page: 1,
          page_size: 0,
        },
      })
      let playlistcontent = await playlistcontentCall({
        data: {
          ...playloadObj,
          page: 1,
          page_size: playlistRes.data.total,
        },
      })

      if (playlistcontent.data && playlistcontent.data?.success) {
        let sortedData =
          contentObj?.playlist_data?.playlist_type === 'MANUAL'
            ? playlistcontent.data?.data.sort((a, b) => {
                let sortingArr = contentObj?.playlist_data?.content_data
                return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
              })
            : playlistcontent.data?.data

        setPlayListContents(sortedData)
        if (!isEmpty(sortedData)) {
          setCurrentPlayingContent(sortedData[0])
        }
      }
      setProcessingLoader(false)
    }
    if (playlistDetail && !playlistDetail.success) {
      setProcessingLoader(false)
    }
  }, [playlistDetail])

  useEffect(async () => {
    if (!isEmpty(currentPlayingContent)) {
      if (await checkRestriction()) {
        getContentWatchUrl({ url: `${CONTENT_WATCH_URL}${currentPlayingContent.content_id}` })
      }
    }
  }, [currentPlayingContent, isLogin])

  const checkPlanExist = (contentActiveSubscription, userSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, userSubscription, isEqual))
  }

  const emptySubcheck = async (contentSub) => {
    const subRes = await activeSubCall()
    const activeSub = subRes.data.data
      .filter((item) => item.status)
      .map((item) => item.subscription)
    return !isEmpty(intersectionWith(contentSub, activeSub, isEqual))
  }

  const checkRestriction = async (openModal = false) => {
    if (!detailObj.is_restricted) {
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsPlaylistSubscribed(false)
        return false
      } else {
        let contentSub = [
          ...detailObj.active_subscription.map((item) => item.id),
          ...detailObj.active_ppv_subscription.map((item) => item.id),
        ]

        const planExist = isEmpty(activeSubscription)
          ? await emptySubcheck(contentSub)
          : checkPlanExist(contentSub, activeSubscription)

        if (!planExist) {
          setIsPlaylistSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(contentSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const handleChangeVideo = (index) => {
    currentIndexGlobal = index
    setCurrentPlayingContent(playListContents[index])
  }
  const handlePlayingSequence = () => {
    let currentVideoIndex = currentIndexGlobal
    if (currentVideoIndex < 0) return
    if (isShuffleGlobal) {
      let randomNum = getRandomWithOneExclusion(playListContents.length, currentVideoIndex)
      handleChangeVideo(randomNum)
      return
    }
    handleChangeVideo(currentVideoIndex < playListContents.length - 1 ? currentVideoIndex + 1 : 0)
  }

  const menuComponents = [
    {
      title: 'About',
      child: (
        <PlaylistAbout
          description={detailObj?.description}
          releaseDate={detailObj?.release_time}
          artists={detailObj?.artists}
          custom_metadata={detailObj?.custom_metadata}
          category={detailObj?.category}
          sub_category={detailObj?.sub_category}
          venues={detailObj?.venues}
        />
      ),
    },
    {
      title: 'Related Playlist',
      child: <PlaylistRelated type={'content'} data={relatedVideos?.data} />,
    },
    {
      title: 'Reviews',
      child: (
        <ReviewsListing
          type={'content'}
          id={playlistId}
          title={detailObj?.title}
          isRefreshed={playlistLoading}
        />
      ),
    },
    {
      title: 'Comments',
      child: <CommentListing type={'content'} id={playlistId} theme={theme} />,
    },
  ]

  return (
    <div>
      <div className="w-full lg:h-[91.25vh] relative">
        <img
          src={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
          className="w-full h-full absolute z-0 lg:block hidden object-cover"
          alt="poster"
        />
        <div className="w-full lg:h-[91.25vh] flex justify-center items-center z-10 lg:bg-transparent bg-[#1b242f]">
          <div
            className="flex flex-col z-10 2xl:w-[1404px] lg:w-[80%] w-full  lg:bg-transparent bg-[#1b242f]"
            style={{
              aspectRatio: '140/64',
              outline: '2px solid transparent',
            }}
          >
            <div
              style={{
                paddingTop: '12px',
                backgroundColor: '#00000040',
                aspectRatio: '1400/104',
                color: '#fff',
              }}
              className="w-full z-10 lg:flex hidden justify-between lg:rounded-xl lg:pb-6 lg:px-7 px-4"
            >
              <div>
                <p style={{ marginBlockEnd: '0' }} className="font-bold xl:text-3xl text-2xl">
                  {detailObj?.title}
                </p>
                <div className="flex">
                  {!isEmpty(detailObj) &&
                    [...detailObj?.category]
                      .filter((ele, idx) => idx < 9)
                      .map((item, index) => (
                        <p
                          style={{ marginBlockEnd: '0' }}
                          className="text-sm lg:mt-3 mt-1"
                          key={index}
                        >
                          {index !== 0 && <span>{' , '}</span>}
                          {item.name}
                          {detailObj?.category.length - 1 > 8 && index === 8 ? (
                            <span>...</span>
                          ) : (
                            ''
                          )}
                        </p>
                      ))}
                  {/* {detailObj?.category.map((info) => info.name).toString()} */}
                </div>
              </div>
              <div className="lg:block hidden">
                <p style={{ marginBlockEnd: '0' }} className="text-sm mt-3 ">
                  Published on {getReleaseDate(detailObj?.publish_time)}
                </p>
              </div>
            </div>
            <div
              style={{
                aspectRatio: '1400/524',
                outline: '2px solid transparent',
                color: '#fff',
              }}
              className="w-full z-10 flex lg:flex-row flex-col justify-between lg:mt-3 lg:gap-x-3"
            >
              <div
                style={{
                  backgroundColor: '#000000',
                  outline: '2px solid transparent',
                  aspectRatio: '39/22',
                  overflow: 'hidden',
                }}
                className="z-10 flex justify-center items-center lg:w-2/3  lg:rounded-xl"
              >
                {videoSource && (
                  <PlaylistPlayer
                    contentInfo={currentPlayingContent}
                    contentWatchUrl={videoSource}
                    resumeTime={'0'}
                    handlePlayingSequence={handlePlayingSequence}
                  />
                )}
                {!videoSource && !isPlaylistSubscribed && (
                  <div className=" w-[70%] md:w-fit">
                    <div className="flex px-5 md:px-0">
                      <img
                        src={LockIcon}
                        style={{
                          width: '18.5px',
                          height: '24.6px',
                        }}
                        alt="LockIcon"
                      />
                      <p
                        style={{ color: '#cecece' }}
                        className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                      >
                        This Playlist is not included in your current Membership Plan.
                      </p>
                    </div>
                    <button
                      style={{ background: '#09e9e9', color: '#000' }}
                      className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                      onClick={() => checkRestriction(true)}
                    >
                      Upgrade your Membership Plan to Watch Now
                    </button>
                  </div>
                )}
              </div>
              <div
                style={{
                  paddingTop: '12px',
                  aspectRatio: '1400/104',
                  color: '#fff',
                  backgroundColor: '#00000040',
                }}
                className="w-full z-10 lg:hidden flex justify-between lg:rounded-xl lg:pb-6 lg:px-7 px-4"
              >
                <div>
                  <p style={{ marginBlockEnd: '0' }} className="font-bold xl:text-3xl text-2xl">
                    {detailObj?.title}
                  </p>
                  <p style={{ marginBlockEnd: '0' }} className="text-sm lg:mt-3 mt-1">
                    {!isEmpty(detailObj) &&
                      detailObj?.category
                        .filter((ele, idx) => idx < 5)
                        .map((info) => info.name)
                        .toString()}
                  </p>
                </div>
                <div className="lg:block hidden">
                  <p style={{ marginBlockEnd: '0' }} className="text-sm mt-3 ">
                    Published on {getReleaseDate(detailObj?.publish_time)}
                  </p>
                </div>
              </div>
              <div
                style={{
                  outline: '2px solid transparent',
                }}
                className="flex flex-col overflow-hidden lg:w-1/3 lg:h-[35vw] 2xl:h-auto lg:rounded-xl"
              >
                <div
                  style={{
                    height: '49px',
                    paddingBottom: '9px',
                    backgroundColor: '#00000040',
                  }}
                  className="flex justify-between lg:pt-3 pt-1 lg:border-0 border-b lg:px-7 px-4"
                >
                  <p className="font-semibold text-xl">
                    {!playlistcontentLoading && !processingLoader
                      ? `${playListContents.length} Videos`
                      : ''}
                  </p>
                  <div style={{ width: '21%' }} className="flex justify-between items-center">
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                        boxSizing: 'content-box',
                        background: !isShuffle ? 'black' : 'none',
                      }}
                      src={LoopIcon}
                      alt=""
                      className="p-1 rounded-md cursor-pointer"
                      onClick={() => {
                        isShuffleGlobal = false
                        setIsShuffle(false)
                      }}
                    />
                    <img
                      style={{
                        width: '24px',
                        height: '24px',
                        background: isShuffle ? 'black' : 'none',
                        boxSizing: 'content-box',
                      }}
                      src={ShuffleIcon}
                      alt=""
                      className="p-1 rounded-md cursor-pointer"
                      onClick={() => {
                        isShuffleGlobal = true
                        setIsShuffle(true)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#00000040',
                    padding: '3% 6%',
                    maxHeight: `471px`,
                    overflowX: 'hidden',
                  }}
                  className="lg:mt-1 h-full scrollbar-class"
                >
                  {!isEmpty(playListContents) &&
                    playListContents.map((info, index) => {
                      let duration = info?.source_asset_id?.file_metadata?.duration
                        ? convertDuration(info?.source_asset_id?.file_metadata?.duration)
                        : ''
                      return (
                        <div
                          key={index}
                          className={`${index && 'mt-6'} flex w-full`}
                          style={{
                            outline: '2px solid transparent',
                          }}
                        >
                          <div
                            style={{ minWidth: '150px', height: '85px' }}
                            className="relative flex justify-center items-center cursor-pointer"
                            onClick={() => handleChangeVideo(index)}
                          >
                            <CustomImage
                              imageUrl={getImage(accountObj?.cf_domain_name, info?.thumbnail)}
                              imgClass="w-full h-full z-0 absolute rounded-lg"
                              loaderClass={'w-full h-full z-0 absolute rounded-lg'}
                              imgAlt={'play'}
                            />
                            {videoSource &&
                            currentPlayingContent?.content_id === info?.content_id ? (
                              <div
                                style={{ width: '20px', height: '20px', background: '#ffffff95' }}
                                className="absolute z-10 flex justify-center items-center rounded-full"
                              >
                                <img src={PauseIcon} alt="PauseIcon" />
                              </div>
                            ) : (
                              <img
                                style={{ width: '20px', height: '20px' }}
                                className="absolute z-10"
                                src={PlayIcon}
                                alt="PlayIcon"
                              />
                            )}
                          </div>
                          <div style={{ marginLeft: '5%' }}>
                            <p className="font-semibold 2xl:text-xl xl:text-lg lg:text-base line-clamp-1">
                              {info.title}
                            </p>
                            <p className="xl:text-sm text-xs xl:mt-3 mt-1">
                              {info?.category
                                .filter((ele, idx) => idx < 4)
                                .map((info) => info.name)
                                .toString()}
                            </p>
                            <p className="xl:text-sm text-xs">{duration}</p>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlaylistBottom options={menuComponents} />
    </div>
  )
}

export default PlaylistHome
