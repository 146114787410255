import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const WedgedInfo = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }
  return (
    <div
      className={` ${isEdit && hoverState && 'component-hover-class'} relative`}
      style={{ backgroundColor: theme.c1, color: theme.c3 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className=" w-full 2xl:py-14 xl:py-10 lg:py-8 md:py-5 py-[8px]">
        <h2 className="xl:text-3xl md:text-xl text-xs font-bold sm:mb-[2vw] mb-6 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[10px]">
          {data?.title}
        </h2>
        <div
          style={{ color: theme.c4 }}
          className={`w-full showcase 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[10px] pb-8 flex  flex-wrap justify-between`}
        >
          {/* ${
                idx && 'md:ml-6 2xl:ml-[1.6vw]'
              } */}
          {data?.items.map((item, idx) => (
            <div
              className={`cursor-pointer lg:w-[27.1vw] md:w-[40.65vw] sm:w-[67.8vw] w-full lg:h-[32vw] md:h-[48.4vw] sm:h-[80.7vw] h-[393px] bg-white flex flex-col justify-start items-center shadow-2xl mb-7`}
              key={idx}
              style={{ backgroundColor: theme.c10 }}
              onClick={() => handleRedirect(item?.link)}
            >
              <CustomImage
                imageUrl={getTemplateImage(item?.image_s3key)}
                imgClass="sm:h-[48%] h-[211px] w-full"
                loaderClass="sm:h-[48%] h-[211px] w-full"
                loaderStyle={{ background: '#5A5A5A50' }}
                imgAlt={'trainer'}
              />
              <div
                className="sm:h-[40%] h-[128px] w-full 2xl:px-[22px] px-[1rem] 2xl:pt-[4%] xl:pt-[3%] sm:pt-[8px] pt-[16px] flex flex-col justify-start 2xl:gap-y-5 xl:gap-y-3 gap-y-2"
                style={{ backgroundColor: theme.c10 }}
              >
                {/* responsiveness of main content  */}
                <div>
                  <h2 className="2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-2xl text-xs font-semibold  lg:mb-[.25ch] sm:mb-[.5ch] mb-[.125pc]">
                    {item?.heading}
                  </h2>
                  <p className="2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xl text-xs font-light italic lg:mb-[.25ch] sm:mb-[1ch] mb-[.25ch]">
                    {item?.sub_heading}
                  </p>
                </div>
                <div className="flex justify-start ">
                  <pre
                    style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}
                    className=" 2xl:text-base xl:text-sm md:text-xs sm:text-base text-[10px] font-normal"
                  >
                    {item?.description}
                  </pre>
                </div>

                {/* <ul
              
                style={{ marginBlockEnd: '0', paddingInlineStart: '1.25em' }}
              >
                {item.description.map((desc, index) => (
                  <li className="" key={index}>
                    {desc.text}
                  </li>
                ))}
              </ul> */}
              </div>
              <div className="bg-white h-[12%] w-full 2xl:px-[22px] px-[1rem] flex justify-between items-center">
                <p
                  className="flex justify-center items-center"
                  style={{ color: theme.c5, marginBottom: '0' }}
                >
                  {/* <span className="lg:text-sm text-xs pr-1 sm:self-start">$</span> */}
                  <span className="font-semibold 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-2xl text-sm">
                    {item?.price_tag}
                  </span>
                  {/* <span className="lg:text-sm text-xs sm:self-end">1 Year</span> */}
                </p>
                {item?.button_text && (
                  <button
                    className="sm:px-[2.75ch] px-[11px] xl:py-[.6ch] lg:py-[.4ch] md:py-[.3ch] sm:py-[.3ch] py-[2px] rounded-3xl font-semibold 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px]"
                    style={{
                      color: theme.c5,
                      border: `1px solid currentColor`,
                      backgroundColor: theme.c2,
                    }}
                    onClick={() => handleRedirect(item.link)}
                  >
                    {item?.button_text}
                  </button>
                )}
              </div>
            </div>
          ))}
          {!data?.is_tripple && (
            <div
              className={`hidden lg:block lg:w-[27.1vw] md:w-[40.65vw] sm:w-[67.8vw] w-full lg:h-[32vw] md:h-[48.4vw] sm:h-[80.7vw] h-[393px] mb-7`}
            ></div>
          )}
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(WedgedInfo)
