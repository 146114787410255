import React, { useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import './globalStyles.css'
import './swimlane.css'
import ContentCardHover from '../../contentCard/contentCardHover'
import ContentCard from '../../contentCard/contentCard'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import { createSlug } from '../templateFunctions'
import AddSection from '../addSection'

const DefaultSwimlane = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  //   const { data, title, filters, seeMoreVisible, type, theme } = props
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { customNavigate, isBrowser, LG_SIZE, SM_SIZE, CONTENT_FILTER_XAVIER } = useTemplateHook()
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [contentData, setContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(5)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(3)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data.content_data) || !isEmpty(data.auto_payload)) {
      let filterPayload =
        data.type === 'STATIC'
          ? { content_ids: data.content_data }
          : {
              system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
              custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
              video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
            }

      contentListCall({
        data: {
          filters: filterPayload,
          page: 1,
          order_by:
            data.type === 'STATIC'
              ? ''
              : data?.order_by === 'title'
              ? `title.${language}`
              : data?.order_by,
          reverse: data.type === 'STATIC' ? '' : data?.reverse,
          page_size: 15,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleHover = (index) => {
    if (isEdit) return
    setHoverCardIndex(index)
  }

  const handleUnHover = () => {
    if (isEdit) return
    setHoverCardIndex('')
  }

  const handleSeeMore = () => {
    let payloadObj = {
      filters: {},
      order_by:
        data.type === 'STATIC'
          ? ''
          : data?.order_by === 'title'
          ? `title.${language}`
          : data?.order_by,
      reverse: data.type === 'STATIC' ? '' : data?.reverse,
    }
    payloadObj.filters =
      data.type === 'STATIC'
        ? {
            content_ids: data.content_data,
          }
        : {
            system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
          }
    const encoded = btoa(JSON.stringify(payloadObj))
    customNavigate(
      'DIRECT',
      `/content/filter/${createSlug(data.name)}?filters=${encoded}&filterV2=true`,
      isEdit,
    )
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  return (
    <div
      // ${
      //   isEdit
      //     ? 'pb-10 relative lg:pt-12 xl:pt-10 2xl:pt-14 mt-2'
      //     : 'mt-6 md:mt-4 lg:mt-20 xl:mt-16 2xl:mt-24 '
      // }
      className={`${
        isEdit && hoverState && 'component-hover-class'
      } pb-10 relative lg:pt-12 xl:pt-10 2xl:pt-14 mt-2
      `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div style={{ backgroundColor: theme.c1 }} className="flex items-baseline">
        <p
          // style={{ color: isCustomTemplate ?  : '#fff' }}
          style={{ color: theme.c3 }}
          className="sm:text-[0.8rem] md:text-[1.5rem] font-bold ml-6 md:ml-16 xl:ml-28"
        >
          {data?.name}
        </p>
        {contentData.length > 14 && (
          <p
            style={{ cursor: 'pointer' }}
            className={
              'text-blue-400 text-xs md:text-md  ml-2 cursor-pointer hover:opacity z-30 mt-3'
            }
            onClick={handleSeeMore}
          >
            {/* {t('see_more')} */}
            See More
          </p>
        )}
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={contentData.length}
        visibleSlides={visibleSlides}
        step={5}
      >
        <div
          className={
            isEmpty(contentData)
              ? 'animate-pulse swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[14rem]'
              : hoverCardIndex !== ''
              ? 'swimlane-container-hover'
              : 'swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[14rem]'
          }
          style={{ backgroundColor: theme.c1 }}
        >
          <Slider
            style={{
              minHeight: hoverCardIndex !== '' && isBrowser ? `24.3rem` : 'unset',
              maxHeight: '261px',
            }}
            className=""
          >
            {contentData.map((item, index) => {
              return (
                <Slide key={index} index={index} className="">
                  {hoverCardIndex === index && isBrowser ? (
                    <div className="pt-10" onMouseLeave={handleUnHover}>
                      <ContentCardHover data={item} type={'content'} />
                    </div>
                  ) : (
                    <div
                      className="pt-2 md:pt-4 lg:pt-8 xl:pt-10"
                      onMouseOver={() => handleHover(index)}
                    >
                      <ContentCard data={item} type={'content'} isEdit={isEdit} />
                    </div>
                  )}
                </Slide>
              )
            })}
          </Slider>
          {isBrowser && (
            <>
              {contentData.length > visibleSlides ? (
                <ButtonBack>
                  <IoIosArrowBack className="left-Swimlane-arrow " />
                </ButtonBack>
              ) : null}
              {contentData.length > visibleSlides ? (
                <ButtonNext>
                  <IoIosArrowForward className="right-Swimlane-arrow " />
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(DefaultSwimlane)
