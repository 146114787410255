import React, { useContext } from 'react'
import { isEmpty } from '../../helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const GATEWAY_IMAGE_URL = {
  Razorpay: 'https://d9iury9bvhjgq.cloudfront.net/razorpay_payment_gateway.png',
  PayU: 'https://d9iury9bvhjgq.cloudfront.net/PayU.svg',
  Stripe: 'https://d9iury9bvhjgq.cloudfront.net/Stripe_payment_logo.png',
  PayPal: 'https://d9iury9bvhjgq.cloudfront.net/PayPal_payment_logo.png',
  Paystack: 'https://d9iury9bvhjgq.cloudfront.net/paystack_payment_logo.png',
}

const GatewayModal = ({ data, onCancel, createTransaction, theme }) => {
  const { t } = useTranslation()
  const { isCustomTemplate } = useContext(AppContext)
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-5/6 xl:w-5/12 my-2 mx-auto">
          <div
            style={{
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              border: `1px solid ${theme.c5}`,
              backgroundColor: isCustomTemplate ? theme.c10 : '',
            }}
            className={`${
              !isCustomTemplate && 'bg-gradient-to-tr from-white via-blue-200 to-gray-500 '
            } shadow-lg relative flex flex-col md:w-full p-5 `}
          >
            <div className="object-right-top">
              <button className="float-right mr-2" onClick={onCancel}>
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#000' }}
                  className=" h-6 w-6 text-2xl"
                >
                  ×
                </span>
              </button>
              <div className="flex justify-start">
                <h4
                  style={{ color: isCustomTemplate ? theme.c4 : '#000' }}
                  className=" text-lg md:text-2xl  font-semibold"
                >
                  {t('select_payment_gateway')}
                </h4>
              </div>
            </div>
            <div className="grid md:grid-cols-3 gap-2 mt-2">
              {!isEmpty(data) &&
                data.map((info, index) => {
                  return (
                    <div
                      className="bg-white w-full cursor-pointer shadow rounded-lg px-8 py-4 relative z-30 my-4 border border-gray-400 hover:border-black"
                      key={index}
                      onClick={() => {
                        createTransaction(info._id)
                        onCancel()
                      }}
                    >
                      <img
                        src={GATEWAY_IMAGE_URL[info.org]}
                        alt={info.org}
                        className="w-full h-20"
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(GatewayModal)
