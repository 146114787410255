import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { LOGIN_API, SIGNUP_API } from '../../apiUrls'
import { raiseToast } from '../../helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const LoginRegister = ({ theme }) => {
  const { t } = useTranslation()
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorData, setErrorData] = useState({})
  const [referralCode, setReferralCode] = useState('')

  const {
    setUser,
    setLoader,
    setOpenLoginModal,
    modalType,
    setModalType,
    setOpenForgotPasswordModal,
    isCustomTemplate,
  } = useContext(AppContext)

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: signUpRes, loading: signUpLoading }, signUpCall] = useAxios(
    {
      url: SIGNUP_API,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(loginResLoading || signUpLoading)
  }, [loginResLoading, signUpLoading])

  const handleForgotPassword = () => {
    setOpenLoginModal(false)
    setOpenForgotPasswordModal(true)
  }

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}

    if (type === 'login') {
      if (loginEmail.trim().length === 0) {
        isFormValid = false
        temp.loginEmail = `${t('email_empty')}!`
      }

      if (loginPassword.trim().length === 0) {
        isFormValid = false
        temp.loginPassword = `${t('password_empty')}!`
      }
    }
    if (type === 'register') {
      if (name.trim().length === 0) {
        isFormValid = false
        temp.name = `${t('user_name_empty')}!`
      }
      if (email.trim().length === 0) {
        isFormValid = false
        temp.email = `${t('email_empty')}!`
      }

      if (!email.includes('@') && email.trim().length !== 0) {
        isFormValid = false
        temp.email = `${t('invalid_email')}!`
      }

      if (password !== confirmPassword) {
        isFormValid = false
        temp.confirmPassword = `${t('passwords_match')}!`
      }
      if (password.trim().length === 0) {
        isFormValid = false
        temp.password = `${t('password_empty')}!`
      }
      if (confirmPassword.trim().length === 0) {
        isFormValid = false
        temp.confirmPassword = `${t('password_empty')}!`
      }
    }
    setErrorData({ ...temp })
    return isFormValid
  }

  const handleLogin = async () => {
    if (validateInfo('login')) {
      loginCall({
        data: {
          email_id: loginEmail,
          password: loginPassword,
        },
      })
    }
  }

  useEffect(() => {
    if (loginRes && loginRes?.success) {
      setUser(loginRes?.data)
      emptyState('login')
      setOpenLoginModal(false)
    } else {
      setErrorData({ loginEmail: loginRes?.error })
    }
  }, [loginRes])

  useEffect(() => {
    if (signUpRes && signUpRes?.success) {
      raiseToast('success', t('register_success_toast'))
      loginCall({
        data: {
          email_id: email,
          password: password,
        },
      })
    }
  }, [signUpRes])

  const handleRegister = async () => {
    if (validateInfo('register')) {
      signUpCall({
        data: {
          email_id: email,
          password: password,
          name,
          phone,
          referral_code: referralCode,
        },
      })
    }
  }

  const emptyState = (type) => {
    setErrorData({})
    if (type === 'login') {
      setLoginEmail('')
      setLoginPassword('')
    }
    if (type === 'register') {
      setName('')
      setEmail('')
      setPhone('')
      setPassword('')
      setConfirmPassword('')
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  return (
    <>
      {modalType === 'login' ? (
        <div
          style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
        >
          <div className=" relative my-6 w-11/12 md:w-10/12 lg:w-8/12 xl:w-5/12 ">
            <div
              style={{
                background: isCustomTemplate ? theme.c10 : '#151c26',
                fontFamily: 'Arial',
                opacity: '0.95',
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.80)',
                border: '',
              }}
              className="shadow-lg relative flex flex-col p-1 md:p-8"
            >
              <div className="py-2 object-right-top">
                <button
                  className="float-right mr-2"
                  onClick={() => {
                    setOpenLoginModal(false)
                    emptyState('login')
                  }}
                >
                  <span
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className=" h-6 w-6 text-2xl"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <h3
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" text-3xl font-semibold"
                >
                  {t('sign_in')}
                </h3>
              </div>
              <div className="relative mt-10 py-6  px-10 md:px-20 flex-auto justify-center ">
                <input
                  type="email"
                  name="loginEmail"
                  value={loginEmail}
                  onChange={(e) => handleChange(e, setLoginEmail)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={t('email')}
                />
                {errorData?.loginEmail && (
                  <div className="error-message">
                    <p>{errorData.loginEmail}</p>
                  </div>
                )}

                <input
                  type="password"
                  name="loginPassword"
                  value={loginPassword}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  onChange={(e) => handleChange(e, setLoginPassword)}
                  className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={t('password')}
                />
                {errorData?.loginPassword && (
                  <div className="error-message">
                    <p>{errorData.loginPassword}</p>
                  </div>
                )}
                <div className="mt-4 flex justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <input
                        id="remember"
                        type="checkbox"
                        defaultChecked={true}
                        className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                      />
                    </div>
                    <div style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className=" ml-2">
                      <label htmlFor="remember" className=" text-sm md:text-lg font-medium">
                        {t('remember_me')}
                      </label>
                    </div>
                  </div>
                  <div className=" ml-3 md:ml-10">
                    {/*<label*/}
                    {/*  htmlFor="remember"*/}
                    {/*  style={{ color: "#3686d6", cursor: "pointer" }}*/}
                    {/*  className="text-sm md:text-lg italic hover:underline"*/}
                    {/*>*/}
                    {/*  Forgot Password?*/}
                    {/*</label>*/}
                  </div>
                </div>
                {/*<div className="mt-6 flex item-center flex-col">*/}
                {/*  <div>*/}
                {/*    <h2*/}
                {/*      style={{ lineHeight: '0.1em' }}*/}
                {/*      className="text-gray-200 text-xl text-center w-full border-b"*/}
                {/*    >*/}
                {/*      <span style={{ background: '#1b242f', padding: '0 10px' }}>or</span>*/}
                {/*    </h2>*/}
                {/*    <p className="text-center mt-6 text-white text-lg">Sign in with</p>*/}
                {/*  </div>*/}

                {/*  <div className="flex mt-6 justify-center">*/}
                {/*    <div className="w-6 cursor-pointer mr-5">*/}
                {/*      <img*/}
                {/*        src="https://upload.wikimedia.org/wikipedia/commons/archive/5/53/20210618182605%21Google_%22G%22_Logo.svg"*/}
                {/*        alt="google"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="w-6 cursor-pointer ml-5">*/}
                {/*      <img*/}
                {/*        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"*/}
                {/*        alt="fb"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className={'flex justify-center'}>
                  <button
                    style={{
                      backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                      color: isCustomTemplate ? theme.c7 : '#fff',
                    }}
                    className="mt-10 ml-2 px-6 rounded focus:outline-none"
                    onClick={handleLogin}
                  >
                    {t('sign_in')}
                  </button>
                </div>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-center p-6 text-sm md:text-lg xl:text-xl">
                <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className="">
                  {t('not_registered')}
                </p>
                <p
                  style={{ color: isCustomTemplate ? theme.c5 : '#3686d6', cursor: 'pointer' }}
                  className="ml-2 hover:underline"
                  onClick={() => {
                    setModalType('register')
                    emptyState('login')
                  }}
                >
                  {t('register')}
                </p>
              </div>
              <div className={'flex justify-center'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c5 : '#3686d6', cursor: 'pointer' }}
                  className="ml-2 hover:underline"
                  onClick={handleForgotPassword}
                >
                  {'Forgot Password ?'}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
        >
          <div className="relative my-6 mx-auto w-11/12 md:w-10/12 lg:w-8/12 xl:w-5/12">
            {/*content*/}
            <div
              style={{
                background: isCustomTemplate ? theme.c10 : '#151c26',
                fontFamily: 'Arial',
                opacity: '0.90',
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
                border: '',
              }}
              className="shadow-lg relative flex flex-col w-full p-8 "
            >
              {/*header*/}
              <div className=" py-2 object-right-top">
                <button
                  className="float-right mr-2"
                  onClick={() => {
                    setOpenLoginModal(false)
                    emptyState('register')
                  }}
                >
                  <span
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className=" h-6 w-6 text-2xl"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="flex justify-center">
                <h3
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="text-3xl font-semibold"
                >
                  {t('register')}
                </h3>
              </div>
              {/*body*/}
              <div className="relative mt-10 py-6  md:px-20 flex-auto">
                {/* <input className="w-full bg-gray" type="email" /> */}

                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e, setName)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className=" border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`${t('name')} *`}
                />
                {errorData?.name && (
                  <div className="error-message">
                    <p>{errorData.name}</p>
                  </div>
                )}
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => handleChange(e, setEmail)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`${t('email')} *`}
                />
                {errorData?.email && (
                  <div className="error-message">
                    <p>{errorData.email}</p>
                  </div>
                )}
                <input
                  type="number"
                  name="phone"
                  min="0"
                  value={phone}
                  onChange={(e) => handleChange(e, setPhone)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`${t('phone')}`}
                />
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => handleChange(e, setPassword)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="mt-6  border  lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`${t('password')} *`}
                />
                {errorData?.password && (
                  <div className="error-message">
                    <p>{errorData.password}</p>
                  </div>
                )}
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => handleChange(e, setConfirmPassword)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="mt-6 border  lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`${t('confirm_password')} *`}
                />
                {errorData?.confirmPassword && (
                  <div className="error-message">
                    <p>{errorData.confirmPassword}</p>
                  </div>
                )}
                <input
                  type="text"
                  name="referralCode"
                  value={referralCode}
                  onChange={(e) => handleChange(e, setReferralCode)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                  placeholder={`Referral code`}
                />
                {/*<div className="mt-6 flex item-center flex-col">*/}
                {/*  <div>*/}
                {/*    <h2*/}
                {/*      style={{ lineHeight: '0.1em' }}*/}
                {/*      className="text-gray-200 text-xl text-center w-full border-b"*/}
                {/*    >*/}
                {/*      <span style={{ background: '#1b242f', padding: '0 10px' }}>or</span>*/}
                {/*    </h2>*/}
                {/*    <p className="text-center mt-6 text-white text-lg">Sign in with</p>*/}
                {/*  </div>*/}

                {/*  <div className="flex mt-6 justify-center">*/}
                {/*    <div className="w-6 cursor-pointer mr-5">*/}
                {/*      <img*/}
                {/*        src="https://upload.wikimedia.org/wikipedia/commons/archive/5/53/20210618182605%21Google_%22G%22_Logo.svg"*/}
                {/*        alt="google"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="w-6 cursor-pointer ml-5">*/}
                {/*      <img*/}
                {/*        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"*/}
                {/*        alt="fb"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className={'flex justify-center'}>
                  <button
                    style={{
                      backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                      color: isCustomTemplate ? theme.c7 : '#fff',
                    }}
                    className="mt-10 ml-2 px-6 rounded focus:outline-none"
                    onClick={handleRegister}
                  >
                    {t('sign_up')}
                  </button>
                </div>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-center p-6 text-sm md:text-lg xl:text-xl">
                <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className="">
                  {t('already_member')}
                </p>
                <p
                  style={{ color: isCustomTemplate ? theme.c5 : '#3686d6', cursor: 'pointer' }}
                  className="ml-2 hover:underline"
                  onClick={() => {
                    setModalType('login')
                    emptyState('register')
                  }}
                >
                  {t('sign_in')}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withTheme(LoginRegister)
