import React from 'react'
import './contentDetail.css'
import Countdown from 'react-countdown'
import FlipClock from './flipCounter'

const LiveCounter = (props) => {
  const {
    contentDetail,
    handleCompletion,
    styles = { top: '70%', left: '60%' },
    counterClass = '',
  } = props
  return (
    <div className={`z-10 xl:absolute py-5 ${counterClass}`} style={styles}>
      <p className={'text-white text-md md:text-3xl text-center mb-2'}>Going Live In</p>
      <Countdown
        date={new Date(contentDetail.publish_time + 'Z').getTime()}
        onComplete={handleCompletion}
        renderer={({ days, hours, minutes, seconds, completed }) => (
          <FlipClock
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            completed={completed}
          />
        )}
      />
    </div>
  )
}

export default LiveCounter
