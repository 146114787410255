import React, { useContext, useEffect, useState } from 'react'
import { CgProfile } from 'react-icons/cg'
import ReactStars from 'react-rating-stars-component'
import useAxios from 'axios-hooks'
import { GET_REVIEWS_API } from '../../apiUrls'
import ReviewModal from './reviewPostModal'
import moment from 'moment'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import RatingsOverall from './ratingsOverall'
import InComponentLoader from '../InComponentLoader'

const ReviewsListing = (props) => {
  const { type, id, title, isRefreshed, theme } = props
  const [reviewModal, setReviewModal] = useState(false)
  const { isLogin, setOpenLoginModal, userObj, isCustomTemplate, accountObj } =
    useContext(AppContext)

  const [{ data: reviewList, loading: reviewListLoading }, getReviews] = useAxios({
    url: GET_REVIEWS_API + '/' + type + '/' + id,
    method: 'get',
  })

  useEffect(() => {
    if (isRefreshed && !accountObj?.od_resource_allowances?.content_refresh) {
      getReviews()
    }
  }, [isRefreshed])

  const onClickWriteReview = () => {
    if (isLogin) {
      setReviewModal(true)
    } else {
      setOpenLoginModal(true)
    }
  }

  const getAlreadyReviewed = () => {
    let obj = {}
    if (reviewList && reviewList.success) {
      reviewList.data.forEach((review) => {
        if (review.user._id === userObj._id) {
          obj = review
        }
      })
    }
    return obj
  }

  return (
    <div>
      <InComponentLoader loading={reviewListLoading} />
      {reviewList?.data.length > 0 && (
        <RatingsOverall ratings={reviewList?.data.map((review) => review.rating)} />
      )}
      <div className={'flex justify-end py-2'} style={{ alignItems: 'center' }}>
        <button
          className="px-2 focus:outline-none ml-2 text-sm underline z-50"
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          onClick={onClickWriteReview}
        >
          {'Write a review'}
        </button>
      </div>
      {!reviewListLoading &&
        reviewList &&
        reviewList.success &&
        reviewList.data.map((review, index) => (
          <div className={'mt-4 border-b pb-4'} key={index}>
            <div className={'flex align-middle mt-2 mb-2'} style={{ alignItems: 'center' }}>
              <CgProfile
                style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2', opacity: '0.85' }}
                className="w-5 h-5"
              />
              <span
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'sm:text-[0.5rem] md:text-[0.8rem] ml-2 '}
              >
                {review.user.name}
              </span>
            </div>
            <div className={'flex'} style={{ alignItems: 'center' }}>
              <ReactStars
                count={5}
                size={24}
                activeColor={isCustomTemplate ? theme.c2 : '#ffd700'}
                value={review.rating}
                edit={false}
                isHalf={true}
              />
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'text-md md:text-md font-bold ml-2'}
              >
                {review.title}
              </p>
            </div>
            <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={'text-sm mb-2'}>
              Reviewed on {moment(new Date(review.created_date + 'Z')).format('DD MMM yyyy')}{' '}
            </p>
            <p
              className={'text-md md:text-md flex'}
              style={{ alignItems: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
            >
              {review.description}
              {'   '}
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'text-sm md:text-sm ml-2'}
              >
                {review.is_edited && '  (edited)'}
              </p>
            </p>
          </div>
        ))}
      {reviewList && reviewList.success && isEmpty(reviewList.data) && (
        <p
          className={'text-md md:text-md font-bold mt-5 '}
          style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
        >
          Not reviewed yet please provide your valuable review
        </p>
      )}
      {reviewModal && (
        <ReviewModal
          id={id}
          type={type}
          title={title}
          handleClose={() => setReviewModal(false)}
          onReviewed={() => getReviews()}
          alreadyReviewed={getAlreadyReviewed()}
        />
      )}
    </div>
  )
}

export default withTheme(ReviewsListing)
