import './app.css'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppProvider } from '../context/app'
import Routing from './routing'
import axios from 'axios'
import { configure } from 'axios-hooks'
import { ContentProvider } from '../context/content'
import SubscriptionProvider from '../context/subscription'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { raiseToast } from '../helper'
import TemplateProvider from '../context/template'

axios.interceptors.request.use(
  async (config) => {
    const sessionId = localStorage.getItem('sessionId')
    const language = localStorage.getItem('language')
    const defaultLanguage = localStorage.getItem('default_language')
    if (sessionId) {
      config.headers['ott-sessionid'] = sessionId
    }
    if (language) {
      config.headers['language'] = language
    }
    if (defaultLanguage) {
      config.headers['default-language'] = defaultLanguage
    }
    config.headers['account-id'] = process.env.REACT_APP_ACCOUNT_ID
    return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (data) => {
    console.log('response', data)
    return data
  },
  (error) => {
    console.log('error', error?.response?.status)
    if (error?.message === 'Network Error') {
      raiseToast('error', 'Check Your Internet Connection')
    } else if (error?.response?.status !== 200) {
      raiseToast('error', error.response?.data?.message)
    }
    return Promise.reject(error)
  },
)

configure({ axios })

function App() {
  return (
    <div className="App">
      <AppProvider>
        <SubscriptionProvider>
          <ContentProvider>
            <TemplateProvider>
              <ToastContainer />
              <BrowserRouter>
                <Routing />
              </BrowserRouter>
            </TemplateProvider>
          </ContentProvider>
        </SubscriptionProvider>
      </AppProvider>
    </div>
  )
}

export default App
