import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'
import ProfileIcon from '../../assets/profile-icon.png'
import { MdOutlineSend } from 'react-icons/md'
import { FaRegKeyboard } from 'react-icons/fa'
import { GrEmoji } from 'react-icons/gr'
import Picker from 'emoji-picker-react'
import { useTranslation } from 'react-i18next'

const CommentInput = (props) => {
  const { theme, handleSubmit, clearInput } = props
  const [inputText, setInputText] = useState('')
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputText) {
      handleSubmit(inputText)
    }
  }

  useEffect(() => {
    if (clearInput) {
      setInputText('')
    }
  }, [clearInput])

  const onEmojiClick = (event, emojiObject) => {
    setInputText((inputText) => inputText.concat(emojiObject.emoji))
  }

  return (
    <>
      <div className="comment-box-wrapperq">
        <div className={'flex align-middle mt-2 mb-2 gap-x-5'} style={{ alignItems: 'center' }}>
          <img src={ProfileIcon} alt="" className="w-10 h-10" />
          <div className="bg-[#0c121a] w-full h-10 rounded-3xl flex justify-end items-center gap-x-6 border">
            <input
              type="text"
              className="bg-inherit w-full rounded-3xl h-full mr-auto px-6"
              placeholder="Write a comment …"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
          </div>
          <span className=" inset-y-0 flex items-center mt-2">
            {emojiPickerOpen ? (
              <FaRegKeyboard
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                onClick={() => setEmojiPickerOpen(false)}
              />
            ) : (
              <GrEmoji
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                onClick={() => setEmojiPickerOpen(true)}
              />
            )}
          </span>
          <MdOutlineSend
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className="h-6 w-6 cursor-pointer"
            onClick={() => handleSubmit(inputText)}
          />
        </div>
      </div>
      {emojiPickerOpen && (
        <div className={`h-[150px] md:h-[200px] lg:h-[250px] xl:h-[250px]`}>
          <Picker
            pickerStyle={{
              width: '100%',
              height: '100%',
              marginTop: '10px',
              boxShadow: 'none',
              borderRadius: '3px',
            }}
            onEmojiClick={onEmojiClick}
            disableSearchBar={true}
            groupNames={{
              smileys_people: t('people_emoji'),
              animals_nature: t('animals_emoji'),
              food_drink: t('food_emoji'),
              travel_places: t('travel_emoji'),
              activities: t('activities_emoji'),
              objects: t('objects_emoji'),
              symbols: t('symbols_emoji'),
              flags: t('flags_emoji'),
              recently_used: t('recently_used'),
            }}
            disableAutoFocus={true}
          />
        </div>
      )}
    </>
  )
}

export default withTheme(CommentInput)
