import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { GET_RENEWAL_SUBSCRIPTION } from '../../apiUrls'
import { AppContext } from '../../context/app'
import { getReleaseDate, raiseToast } from '../../helper'
import { isEmpty } from 'lodash'
import { SubscriptionContext } from '../../context/subscription'
import CancelSubscriptionModal from './cancelSubscriptionModal'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const MySubscriptions = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const { setOpenSubscriptionModal } = useContext(SubscriptionContext)
  const { t } = useTranslation()
  const [cancelShow, setCancelShow] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [subscriptions, setSubscriptions] = useState([])

  const [{ data: subscriptionData, loading: subscriptionDataLoading }, subscriptionDataCall] =
    useAxios(
      {
        url: GET_RENEWAL_SUBSCRIPTION,
        method: 'get',
      },
      { manual: true },
    )

  const [{ data: subscriptionDelete, loading: subscriptionDeleteLoading }, subscriptionDeleteCall] =
    useAxios(
      {
        url: GET_RENEWAL_SUBSCRIPTION,
        method: 'delete',
      },
      { manual: true },
    )

  useEffect(() => {
    subscriptionDataCall()
  }, [])

  useEffect(() => {
    if (subscriptionDelete && subscriptionDelete.success) {
      raiseToast('success', t('subscription_cancel_success_toast'))
      subscriptionDataCall()
    }
  }, [subscriptionDelete])

  useEffect(() => {
    setLoader(subscriptionDataLoading || subscriptionDeleteLoading)
  }, [subscriptionDataLoading, subscriptionDeleteLoading])

  useEffect(() => {
    if (subscriptionData && subscriptionData.success) {
      setSubscriptions(subscriptionData.data)
    }
  }, [subscriptionData])

  const handleCancelSubscription = () => {
    setCancelShow(false)
    subscriptionDeleteCall({
      params: {
        subs_renewal_id: selectedTransaction.id,
      },
    })
  }

  return (
    <div className="min-h-full md:py-8">
      <div className={`text-center xl:mx-20 xl:text-left`}>
        <p
          className="underline text-md lg:text-2xl mb-2 "
          style={{ textTransform: 'capitalize', color: isCustomTemplate ? theme.c3 : '#fff' }}
        >
          {t('my_subscriptions')}
        </p>
      </div>
      {!subscriptionDataLoading && (
        <>
          {!isEmpty(subscriptions) ? (
            subscriptions.map((info, index) => (
              <div className=" flex justify-center py-2 items-center" key={index}>
                <div
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c10 : '#151d27',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(55,65,81)',
                  }}
                  className={`p-4 bg-opacity-90 2xl:h-30 w-11/12 xl:w-3/5  shadow rounded-md relative my-1 border`}
                >
                  <div className="md:grid md:grid-cols-12 gap-2">
                    <div
                      className=" flex flex-col md:col-span-4 "
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    >
                      <p className="md:text-lg xl:text-2xl">{info.subscription_name}</p>
                      <p className="">
                        {t('subscribed_on')} : {getReleaseDate(info.created_date)}
                      </p>
                    </div>
                    <div
                      className="md:flex justify-between col-span-8 "
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    >
                      <div>
                        <p className="">
                          {t('last_renewal_date')} : {getReleaseDate(info.last_renewal_date)}
                        </p>
                        <p className="">
                          {t('valid_till')} : {getReleaseDate(info.next_renewal_date)}
                        </p>
                      </div>
                      <div className="">
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={`md:text-lg xl:text-xl flex ${
                            !(info.renewal_status === 'ongoing') ? 'xl:mr-3' : 'xl:mr-1'
                          }`}
                        >
                          {t('status')} :
                          <p
                            className={`px-2 ${
                              info.renewal_status === 'ongoing' ? 'text-green-500' : 'text-red-600'
                            }`}
                          >
                            {info.renewal_status === 'ongoing' ? t(`active`) : t(`cancelled`)}
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    {info.renewal_status === 'ongoing' && (
                      <button
                        className=" focus:outline-none text-red-600"
                        onClick={() => {
                          setSelectedTransaction(info)
                          setCancelShow(true)
                        }}
                      >
                        {t('cancel')}
                      </button>
                    )}
                    {info.renewal_status !== 'ongoing' && (
                      <p className="text-red-600">
                        {t('cancelled_on')} : {getReleaseDate(info.cancellation_date)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className="h-[60vh] flex flex-col justify-center py-2 items-center "
              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            >
              <p className="md:text-lg xl:text-xl">{t('no_subscriptions_found')} !!</p>
              <button
                className="mt-4 md:mt-10 px-4 py-1 focus:outline-none"
                style={{
                  color: isCustomTemplate ? theme.c7 : '#fff',
                  backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                  borderRadius: '6px',
                }}
                onClick={() => setOpenSubscriptionModal(true)}
              >
                {t('purchase')}
              </button>
            </div>
          )}
        </>
      )}
      {cancelShow && (
        <CancelSubscriptionModal
          onCancel={() => {
            setSelectedTransaction({})
            setCancelShow(false)
          }}
          cancelCallback={handleCancelSubscription}
        />
      )}
    </div>
  )
}

export default withTheme(MySubscriptions)
