import React, { useContext, useEffect, useState } from 'react'
import artistBanner from '../../assets/artistBanner.png'
import { ARTIST_LISTING_DESCRIPTION, ARTIST_LISTING_TITLE } from '../../constants'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { ARTIST_LISTING } from '../../apiUrls'
import ArtistCard from '../../components/artistCard'
import { shuffle } from 'lodash'
import { withTheme } from 'theming'

const ArtistListing = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [dataList, setDataList] = useState([])
  const [{ data: artistList, loading: artistListLoading }] = useAxios({
    url: ARTIST_LISTING,
    method: 'get',
    params: {
      page_size: 1000,
      page: 1,
    },
  })

  useEffect(() => {
    setDataList([])
    // window.addEventListener('scroll', infiniteScroll)
    // return () => {
    //   window.removeEventListener('scroll', infiniteScroll)
    // }
  }, [])

  useEffect(() => {
    if (artistList && artistList.success) {
      setDataList(shuffle(artistList.data))
    }
  }, [artistList])

  useEffect(() => {
    setLoader(artistListLoading)
  }, [artistListLoading])

  // const infiniteScroll = () => {
  //   console.log('heree')
  //   // End of the document reached?
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >
  //     0.8 * document.documentElement.offsetHeight
  //   ) {
  //     if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
  //       apiHit = true
  //       currentPage += 1
  //       hitAPI(currentPage)
  //     }
  //   }
  // }

  return (
    <div>
      <div className="w-full relative">
        <img src={artistBanner} className="w-full" alt="Artist Banner" />
        <div
          style={{ color: isCustomTemplate ? theme.c7 : '#fff' }}
          className=" absolute top-1.5 left-3 lg:top-10 lg:left-36"
        >
          <p className="text-xs md:text-4xl font-bold">{ARTIST_LISTING_TITLE}</p>
          <p className="text-xs md:text-base">{ARTIST_LISTING_DESCRIPTION}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 px-4 py-5 md:px-16 md:py-10 lg:px-24 xl:py-6">
        {dataList && dataList.map((item, index) => <ArtistCard item={item} key={index} />)}
      </div>
    </div>
  )
}

export default withTheme(ArtistListing)
