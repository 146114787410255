import React, { useEffect, useState } from 'react'

const RatingsOverall = ({ ratings = [] }) => {
  const [ratingCounts, setRatingCounts] = useState([])
  const ratingCategories = [
    { value: 5, name: '5 ⭐', bg: '#0f8717' },
    { value: 4, name: '4 ⭐', bg: '#27db05' },
    { value: 3, name: '3 ⭐', bg: '#fff131' },
    { value: 2, name: '2 ⭐', bg: '#cc8100' },
    { value: 1, name: '1 ⭐', bg: '#cb0000' },
  ]

  const updateRatingBars = (ratings) => {
    const tempRatingCounts = ratingCategories.map((category) => {
      return {
        name: category.name,
        count: ratings?.filter(
          (rating) => rating === category.value || rating === category.value - 0.5,
        ).length,
      }
    })
    setRatingCounts(tempRatingCounts)
  }

  useEffect(() => {
    updateRatingBars(ratings)
  }, [])

  return (
    <div>
      <div
        className="flex py-5"
        style={{ outline: '2px solid transparent', borderBottom: '1px solid #707070' }}
      >
        <div className="flex flex-col justify-between lg:mr-20 md:mr-10 mr-3">
          <h1 className="font-semibold 2xl:text-lg xl:text-base text-sm">Overall Rating</h1>
          <div style={{ border: '1px solid #707070', borderRadius: '3px' }}>
            <span
              className="px-2 py-1 inline-block font-semibold xl:text-base md:text-sm text-xs"
              style={{ borderRight: '1px solid #707070', textAlign: 'center' }}
            >
              {(ratings?.reduce((a, b) => a + b) / ratings?.length).toFixed(1)} ⭐
            </span>
            <span className="px-2 py-1 xl:text-base md:text-sm text-xs">
              {ratings?.length} Reviews
            </span>
          </div>
        </div>
        <div
          className="rating-viewer flex flex-col items-start lg:gap-y-1.5 gap-y-1"
          // style={{ outline: '2px solid red' }}
        >
          {ratingCategories.map((rating, idx) => (
            <div className="category flex items-center" key={idx}>
              <span className="category-name mr-4 w-52 2xl:text-sm sm:text-xs text-[10px] font-light">
                {rating.name}
              </span>
              <div
                className="bar h-2 rounded-lg mr-4"
                style={{
                  background: rating.bg,
                  transition: 'width 2s ease-in-out',
                  width: window.matchMedia('(max-width: 800px)').matches
                    ? `${(ratingCounts[idx]?.count / ratings?.length) * 150}px`
                    : `${(ratingCounts[idx]?.count / ratings?.length) * 450}px`,
                }}
              />
              <span className="2xl:text-sm sm:text-xs text-[10px] font-light sm:inline hidden">
                {ratingCounts[idx]?.count !== 0 ? ratingCounts[idx]?.count : ''}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RatingsOverall
