import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from '../../context/app'
import { createSlug, getImage, getReleaseDate, raiseToast, secondsToHms } from '../../helper'
import './contentDetail.css'
import TrailerPlayer from '../player/trailerPlayer'
import RedCircle from '../../assets/red_circle.gif'
import GreyCircle from '../../assets/greycircle.png'
import Player from '../player/player'
import useAxios from 'axios-hooks'
import { SubscriptionContext } from '../../context/subscription'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import { CONTENT_WATCH_URL, DETAIL_CONTENT, RESUME_TIMESTAMP_API } from '../../apiUrls'
import { useLocation, useSearchParams } from 'react-router-dom'
import LiveCounter from './liveCounter'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import VideoPlaceholder from '../../assets/video-content.png'
import ShowMoreText from 'react-show-more-text'
import RestartLogo from '../../assets/start-again-icon.svg'
import ShareIcon from '../../assets/share-icon.svg'
import RatingReviewWidget from '../../components/reviewRating/ratingReviewWidget'
import { withTheme } from 'theming'
import ContentWrapper from './_contentDetailStyle'
import ShareModal from '../../components/shareModal/shareModal'
import ContentDetailBottom from './contentDetailBottom'
import { ContentContext } from '../../context/content'
import AddWatchlist from '../../assets/add-watchlist.svg'
import AddFavourite from '../../assets/add-favourite.svg'
import AddedWatchlist from '../../assets/added-watchlist.svg'
import AddedFavourite from '../../assets/added-favourite.svg'

const ContentDetail = ({ theme }) => {
  const contentId = useParams().contentId
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { t } = useTranslation()
  const {
    isCustomTemplate,
    isLogin,
    setLoader,
    setOpenLoginModal,
    setModalType,
    accountObj,
    modulePermissions,
  } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const {
    isInFavoriteList,
    isInWatchList,
    addRemoveFavouriteList,
    addRemoveWatchList,
    setIsVideoPlaying,
  } = useContext(ContentContext)

  const [publishDateTime, setPublishDateTime] = useState(null)
  const [playingVideo, setPlayingVideo] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [detailObj, setDetailObj] = useState()
  const [isLive, setIsLive] = useState(false)
  const [resumeTime, setResumeTime] = useState(0)
  const navigate = useNavigate()
  const isWatch = searchParams.get('watch') === 'true'
  const [endTime, setEndTime] = useState(null)
  const [shareModal, setShareModal] = useState(false)

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: resumeTimeData, loading: resumeTimeLoading }, resumeTimeCall] = useAxios(
    {
      url: `${RESUME_TIMESTAMP_API}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (resumeTimeData && resumeTimeData.success) {
      setResumeTime(resumeTimeData?.data?.resume_timestamp)
    }
  }, [resumeTimeData])

  // useInterval(async () => {
  //   if (accountObj?.od_resource_allowances?.content_refresh) {
  //     await contentDetailCall()
  //   }
  // }, 10000)

  useEffect(() => {
    if (!playingVideo) {
      contentDetailCall()
    }
  }, [playingVideo, contentId])

  useEffect(() => {
    if (!isEmpty(detailObj) && isWatch) {
      handleWatchNow()
    }
  }, [detailObj])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setShowMore(false)
      setDetailObj(contentObj)
      if (contentObj.video_type.includes('LIVE')) {
        setPublishDateTime(new Date(contentObj.publish_time + 'Z').getTime())
        setEndTime(new Date(contentObj.live_expiry_time + 'Z').getTime())
        if (
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            contentObj.video_type === 'VOD_LIVE') ||
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            new Date(contentObj.live_expiry_time + 'Z').getTime() > Date.now())
        ) {
          setIsLive(true)
        }
      }
      if (isLogin) {
        resumeTimeCall({ params: { content_id: contentId } })
      }
    }
  }, [contentDetail])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setLoader((isEmpty(detailObj) && contentLoading) || contentWatchUrlLoading || resumeTimeLoading)
  }, [contentLoading, contentWatchUrlLoading, resumeTimeLoading])

  const checkPlanExist = (contentActiveSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, activeSubscription, isEqual))
  }

  const get_video_url = (detailObj) => {
    if (detailObj.video_type === 'LIVE') {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    } else {
      getContentWatchUrl()
    }
  }

  const handleWatchNow = () => {
    if (detailObj.video_type === 'LIVE' && endTime < Date.now()) {
      raiseToast('error', t('stream_ended_toast'))
      return
    }
    if (!detailObj.is_restricted) {
      if (detailObj.is_login_required && !isLogin) {
        setModalType('login')
        setOpenLoginModal(true)
      } else {
        get_video_url(detailObj)
      }
    } else {
      if (!isLogin) {
        setModalType('login')
        setOpenLoginModal(true)
      } else {
        let activeSub = [
          ...detailObj.active_subscription.map((item) => item.id),
          ...detailObj.active_ppv_subscription.map((item) => item.id),
        ]
        const planExist = checkPlanExist(activeSub)
        if (!planExist) {
          setRedirectionUrl(window.location.pathname)
          setSubscriptions(activeSub)
          setOpenSubscriptionModal(true)
        } else {
          get_video_url(detailObj)
        }
      }
    }
  }

  const getButtonText = () => {
    let activeSub = [
      ...detailObj.active_subscription.map((item) => item.id),
      ...detailObj.active_ppv_subscription.map((item) => item.id),
    ]
    const planExist = checkPlanExist(activeSub)
    if (!isLogin || !detailObj.is_restricted || planExist) {
      return resumeTime ? t('resume_watching') : t('watch_now')
    } else {
      return t('upgrade_plan')
    }
  }

  const handleCategoryClick = (id, name) => {
    const encoded = btoa(JSON.stringify({ filters: { category: [id] } }))
    navigate(`/category/${id}?category=${btoa(name)}&filters=${encoded}`)
  }

  const handleSubCategory = (id, name) => {
    const encoded = btoa(JSON.stringify({ filters: { sub_category: [id] } }))
    navigate(`/content/filter/${createSlug(name)}?filters=${encoded}`)
  }

  const handleCustomMetadata = (id, value, name) => {
    value = value.trim()
    const encoded = btoa(JSON.stringify({ filters: { custom: { [id]: [value] } } }))
    navigate(`/content/filter/${createSlug(value)}-${createSlug(name)}?filters=${encoded}`)
  }

  const onCounterComplete = () => {
    setIsLive(true)
    handleWatchNow()
  }

  const executeOnClick = () => {
    setShowMore((showMore) => !showMore)
  }

  const checkUserTicket = () => {
    let activeSub = [
      ...detailObj.active_subscription.map((item) => item.id),
      ...detailObj.active_ppv_subscription.map((item) => item.id),
    ]
    return checkPlanExist(activeSub)
  }

  const buyTicket = () => {
    if (!isLogin) {
      setModalType('login')
      setOpenLoginModal(true)
    } else {
      let activeSub = [
        ...detailObj.active_subscription.map((item) => item.id),
        ...detailObj.active_ppv_subscription.map((item) => item.id),
      ]
      setSubscriptions(activeSub)
      setOpenSubscriptionModal(true)
      setRedirectionUrl(window.location.pathname)
    }
  }

  return (
    <>
      {detailObj && (
        <>
          {playingVideo ? (
            <Player
              contentInfo={detailObj}
              handleClose={() => {
                setPlayingVideo(false)
                setIsVideoPlaying(false)
                setResumeTime(0)
                navigate(location.pathname)
              }}
              contentWatchUrl={
                detailObj.video_type === 'LIVE'
                  ? detailObj.stream.link
                  : contentWatchUrl.data.signed_url
              }
              resumeTime={resumeTime}
            />
          ) : (
            <>
              <ContentWrapper theme={theme}>
                <div className="flex flex-col md:flex">
                  <div className="content-image xl:visible">
                    <div className="content-image-div">
                      {detailObj.video_type === 'LIVE' && !isLive ? (
                        <CustomImage
                          imageUrl={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                          loaderClass="content-loader"
                          imgAlt="content"
                          errorPlaceholder={VideoPlaceholder}
                        />
                      ) : (
                        <>
                          {detailObj.preview ? (
                            <TrailerPlayer
                              preview={detailObj.preview}
                              poster={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                              quality={480}
                              type={'CONTENT'}
                              stream={detailObj.stream}
                              videoType={detailObj.video_type}
                            />
                          ) : (
                            <CustomImage
                              imageUrl={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                              loaderClass="content-loader"
                              imgAlt="content"
                              errorPlaceholder={VideoPlaceholder}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={`z-10 xl:absolute pl-5 md:pl-16 w-11/12`}
                    style={{ color: '#e3e2e2' }}
                  >
                    <p
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                      className={'text-3xl md:text-4xl  font-semibold mt-4'}
                    >
                      {detailObj?.title}
                    </p>
                    {modulePermissions?.rating && (
                      <RatingReviewWidget
                        value={detailObj.average_rating}
                        ratingCount={detailObj.review_count}
                      />
                    )}

                    {detailObj.video_type.includes('LIVE') &&
                      !isLive &&
                      (detailObj.video_type === 'VOD_LIVE' || endTime > Date.now()) && (
                        <LiveCounter
                          contentDetail={detailObj}
                          handleCompletion={onCounterComplete}
                        />
                      )}

                    {detailObj.release_time && (
                      <div className={'flex mt-2'}>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={' font-semibold'}
                        >
                          {t('release_date')} :
                        </p>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={'ml-2'}
                        >
                          {getReleaseDate(detailObj.release_time)}
                        </p>
                      </div>
                    )}
                    {detailObj?.duration !== 0 && (
                      <div style={{ display: 'flex' }}>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={' font-semibold'}
                        >
                          {secondsToHms(detailObj?.duration)}
                        </p>
                      </div>
                    )}
                    <div className="flex items-center mt-5">
                      {!isEmpty(detailObj?.content_rating) ? (
                        <div className="flex items-center justify-center pr-4">
                          {detailObj?.content_rating?.icon?.s3_key && (
                            <div className="mr-1">
                              <img
                                src={getImage(
                                  accountObj?.cf_domain_name,
                                  detailObj?.content_rating?.icon?.s3_key,
                                )}
                                className="w-6 h-6 md:w-10 md:h-10"
                                alt="Rating"
                              />
                            </div>
                          )}
                          <p
                            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                            className="text-base md:text-lg font-semibold"
                          >
                            {detailObj?.content_rating?.name}
                          </p>
                        </div>
                      ) : null}
                      {detailObj.video_type.includes('LIVE') ? (
                        <div style={{ display: 'flex', alignItems: 'center' }} className={''}>
                          <button
                            className="px-4 py-1 focus:outline-none"
                            style={{
                              color: isCustomTemplate ? theme.c7 : '#fff',
                              backgroundColor: !isLive
                                ? '#707070'
                                : isCustomTemplate
                                ? theme.c5
                                : '#1976d2',
                              borderRadius: '6px',
                              textTransform: 'uppercase',
                            }}
                            onClick={handleWatchNow}
                            disabled={
                              publishDateTime > Date.now() ||
                              (endTime < Date.now() && detailObj.video_type === 'LIVE')
                            }
                          >
                            {endTime < Date.now() && detailObj.video_type === 'LIVE'
                              ? t('stream_ended')
                              : !isLive
                              ? t('upcoming')
                              : detailObj.video_type === 'LIVE'
                              ? t('playing_now')
                              : t('watch_now')}
                          </button>
                          {detailObj.video_type === 'LIVE' &&
                            detailObj.is_restricted &&
                            !checkUserTicket() && (
                              <button
                                className="px-4 py-1 ml-2 md:text-sm"
                                style={{
                                  color: '#fff',
                                  backgroundColor: '#1976d2',
                                  borderRadius: '6px',
                                  textTransform: 'uppercase',
                                }}
                                onClick={buyTicket}
                              >
                                {`Buy ticket`}
                              </button>
                            )}

                          {detailObj.video_type === 'LIVE' && endTime > Date.now() && (
                            <div
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '15px',
                                  marginRight: '8px',
                                  fontSize: '1.5rem',
                                  color: isCustomTemplate ? theme.c4 : '#fff',
                                }}
                              >
                                {t('live')}
                              </span>
                              <img
                                src={!isLive ? GreyCircle : RedCircle}
                                style={{ width: '20px', height: '20px' }}
                                alt={'live'}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <button
                            className="px-4 py-1 focus:outline-none"
                            style={{
                              color: isCustomTemplate ? theme.c7 : '#fff',
                              backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                              borderRadius: '6px',
                            }}
                            onClick={() => {
                              // setResumeTime(detailObj?.resume_timestamp)
                              handleWatchNow()
                            }}
                          >
                            {getButtonText()}
                          </button>
                          {detailObj?.resume_timestamp ? (
                            <img
                              src={RestartLogo}
                              className="h-8 w-8 ml-4 xl:ml-8 cursor-pointer"
                              onClick={handleWatchNow}
                              title={t('start_from_begin')}
                              alt="restart"
                            />
                          ) : null}
                        </>
                      )}
                      <img
                        src={ShareIcon}
                        className="h-8 w-8 ml-2 xl:ml-4 cursor-pointer"
                        onClick={() => setShareModal(true)}
                        title={t('share')}
                        alt="share"
                      />

                      {isLogin && (
                        <div className="flex  justify-between ">
                          <div className=" cursor-pointer ml-2 xl:ml-4">
                            {isInWatchList(contentId) ? (
                              <img
                                src={AddedWatchlist}
                                className="h-8 w-8"
                                onClick={() => addRemoveWatchList(contentId)}
                                alt="AddedWatchlist"
                              />
                            ) : (
                              <img
                                src={AddWatchlist}
                                className="h-8 w-8"
                                onClick={() => addRemoveWatchList(contentId)}
                                alt="AddWatchlist"
                              />
                            )}
                          </div>
                          <div className="cursor-pointer ml-2 xl:ml-4 ">
                            {isInFavoriteList(contentId) ? (
                              <img
                                src={AddedFavourite}
                                className="h-8 w-8 "
                                onClick={() => addRemoveFavouriteList(contentId)}
                                alt="AddedFavourite"
                              />
                            ) : (
                              <img
                                src={AddFavourite}
                                className="h-8 w-8 "
                                onClick={() => addRemoveFavouriteList(contentId)}
                                alt="AddFavourite"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {contentId && (
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className={`mt-5  xl:w-2/5 w-full text-lg mr-5 description-color `}
                        anchorClass={`text-sm description-see-more-color`}
                        expanded={showMore}
                        onClick={executeOnClick}
                        truncatedEndingComponent={'... '}
                      >
                        <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}>
                          {detailObj?.description}
                        </p>
                      </ShowMoreText>
                    )}
                    <div className={'md:w-3/5  mt-5 '}>
                      {Object.keys(detailObj.artists).map((artist, index) => (
                        <div className={'flex'} key={index}>
                          <p
                            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                            className={'  font-semibold text-sm md:text-md w-4/12 lg:w-2/12'}
                          >
                            {artist}
                          </p>
                          <div
                            style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                            className={
                              '  text-sm md:text-md  font-semibold w-8/12 lg:w-10/12 flex flex-col  xl:flex-row'
                            }
                          >
                            {detailObj.artists[artist].map((item, index) => (
                              <p
                                key={index}
                                onClick={() => navigate(`/artist/detail/${item.id}`)}
                                className={'cursor-pointer hover:underline'}
                              >
                                {index !== 0 && (
                                  <span className={'hidden xl:contents'}>{' , '}</span>
                                )}
                                {item.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      ))}
                      <div className={'flex'}>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={' font-semibold  text-sm md:text-md w-4/12 lg:w-2/12'}
                        >
                          {t('category')}
                        </p>
                        <div
                          style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                          className={
                            ' text-sm md:text-md font-semibold w-8/12 lg:w-10/12 flex flex-col xl:flex-row'
                          }
                        >
                          {[...detailObj?.category].map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handleCategoryClick(item.id, item.name)}
                              className={'cursor-pointer hover:underline'}
                            >
                              {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                              {item.name}
                            </p>
                          ))}
                        </div>
                      </div>
                      {!isEmpty(detailObj.sub_category) && (
                        <div className={'flex'}>
                          <p
                            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                            className={' font-semibold text-sm md:text-md w-4/12 lg:w-2/12'}
                          >
                            {t('genre')}
                          </p>
                          <div
                            style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                            className={
                              ' text-sm md:text-md font-semibold w-8/12 lg:w-10/12 flex flex-col xl:flex-row'
                            }
                          >
                            {detailObj.sub_category.map((item, index) => (
                              <p
                                key={index}
                                onClick={() => handleSubCategory(item.id, item.name)}
                                className={'cursor-pointer hover:underline'}
                              >
                                {index !== 0 && (
                                  <span className={'hidden xl:contents'}>{' , '}</span>
                                )}
                                {item.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                      {!isEmpty(detailObj.venues) && (
                        <div className={'flex'}>
                          <p
                            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                            className={' font-semibold text-sm md:text-md w-4/12 lg:w-2/12'}
                          >
                            {t('venue')}
                          </p>
                          <div
                            style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                            className={
                              '  text-sm md:text-md font-semibold w-8/12 lg:w-10/12 flex flex-col xl:flex-row'
                            }
                          >
                            {detailObj.venues.map((item, index) => (
                              <p
                                key={index}
                                onClick={() => navigate(`/venue/detail/${item.id}`)}
                                className={'cursor-pointer hover:underline'}
                              >
                                {index !== 0 && (
                                  <span className={'hidden xl:contents'}>{' , '}</span>
                                )}
                                {item.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                      {detailObj.custom_metadata &&
                        detailObj.custom_metadata.map((item, index) => (
                          <div className={'flex'} key={index}>
                            <p
                              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                              className={' font-semibold text-sm md:text-md w-4/12 lg:w-2/12'}
                            >
                              {item.name}
                            </p>
                            <div
                              style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                              className={
                                ' font-semibold text-sm md:text-md w-8/12 lg:w-10/12 flex flex-col xl:flex-row'
                              }
                            >
                              {item.value.split(',').map((obj, index) => (
                                <p
                                  key={index}
                                  onClick={() => handleCustomMetadata(item.id, obj, item.name)}
                                  className={'cursor-pointer  hover:underline'}
                                >
                                  {index !== 0 && (
                                    <span className={'hidden xl:contents'}>{' , '}</span>
                                  )}
                                  {obj}
                                </p>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                    {detailObj.video_type === 'LIVE' && endTime < Date.now() && (
                      <div className={`z-10 xl:absolute py-5`} style={{ top: '35%', left: '60%' }}>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={' text-md md:text-2xl xl:text-3xl text-center mb-2'}
                        >
                          {t('stream_ended')}{' '}
                        </p>
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className={' text-md md:text-2xl xl:text-3xl text-center mb-2'}
                        >
                          {t('thanks_for_watching')}{' '}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </ContentWrapper>
              <ContentDetailBottom detailObj={detailObj} theme={theme} />
            </>
          )}
          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              link={window.location.href}
              detailObj={detailObj}
            />
          )}
        </>
      )}
    </>
  )
}

export default withTheme(ContentDetail)
