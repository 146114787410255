import React, { useContext, useEffect, useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { CHANGE_PASSWORD } from '../../apiUrls'
import { raiseToast } from '../../helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const ChangePassword = ({ setShowModal, theme }) => {
  const { setLoader, userObj, isCustomTemplate, logoutCall } = useContext(AppContext)
  const { t } = useTranslation()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newPasswordToggle, setNewPasswordToggle] = useState(false)
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false)

  const [errorData, setErrorData] = useState({})

  const validateInfo = () => {
    let isFormValid = true
    let temp = {}

    if (newPassword !== confirmPassword) {
      isFormValid = false
      temp.confirmPassword = `${t('passwords_match')}!`
    }
    if (oldPassword.trim().length === 0) {
      isFormValid = false
      temp.oldPassword = `${t('old_password_empty')}!`
    }
    if (newPassword.trim().length === 0) {
      isFormValid = false
      temp.newPassword = `${t('password_empty')}!`
    }
    if (confirmPassword.trim().length === 0) {
      isFormValid = false
      temp.confirmPassword = `${t('password_empty')}!`
    }

    setErrorData({ ...temp })
    return isFormValid
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  const [{ data: changePasswordRes, loading: changePasswordLoading }, changePasswordCall] =
    useAxios(
      {
        url: CHANGE_PASSWORD,
        method: 'post',
      },
      { manual: true },
    )

  useEffect(() => {
    setLoader(changePasswordLoading)
  }, [changePasswordLoading])

  const handleSubmit = async () => {
    if (validateInfo()) {
      changePasswordCall({
        data: {
          user_id: userObj._id,
          new_password: newPassword,
          old_password: oldPassword,
        },
      })
    }
  }

  useEffect(() => {
    if (changePasswordRes && changePasswordRes.success) {
      emptyState()
      raiseToast('success', t('password_success_toast'))
      logoutCall()
      setShowModal(false)
    }
  }, [changePasswordRes])

  const emptyState = () => {
    setErrorData({})
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }
  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none `}
      >
        <div className={`relative my-6 w-10/12 lg:w-8/12 xl:w-5/12`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#151c26',
              fontFamily: 'Arial',
              opacity: '0.96',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.90)',
              border: '',
            }}
            className="shadow-lg relative flex flex-col p-1 md:p-8"
          >
            <div className=" py-2 object-right-top">
              <button
                className="p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={() => {
                  setShowModal(false)
                  emptyState()
                }}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className={` h-6 w-6 text-xl xl:text-2xl block outline-none focus:outline-none`}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="flex justify-center">
              <h3
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={` text-2xl font-semibold`}
              >
                {t('change_password')}
              </h3>
            </div>
            {/*body*/}
            <div className="relative md:mt-4 py-6 px-6 md:px-10 flex-auto">
              <input
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={(e) => handleChange(e, setOldPassword)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className={`mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none`}
                placeholder={`${t('old_password')} *`}
              />
              {errorData?.oldPassword && (
                <div className="error-message">
                  <p>{errorData.oldPassword}</p>
                </div>
              )}
              <div className="relative">
                <input
                  type={newPasswordToggle ? 'text' : 'password'}
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => handleChange(e, setNewPassword)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className={`mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none`}
                  placeholder={`${t('new_password')} *`}
                />

                {newPasswordToggle ? (
                  <BsEye
                    style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2', marginTop: '10px' }}
                    className="absolute right-0 top-2 inset-y-0 mr-3 text-lg"
                    onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                  />
                ) : (
                  <BsEyeSlash
                    style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2', marginTop: '10px' }}
                    className="absolute right-0 top-2 inset-y-0 mr-3 text-lg"
                    onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                  />
                )}
              </div>
              {errorData?.newPassword && (
                <div className="error-message">
                  <p>{errorData.newPassword}</p>
                </div>
              )}
              <div className="relative">
                <input
                  type={confirmPasswordToggle ? 'text' : 'password'}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => handleChange(e, setConfirmPassword)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className={`mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none`}
                  placeholder={`${t('confirm_password')} *`}
                />
                {confirmPasswordToggle ? (
                  <BsEye
                    style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2', marginTop: '10px' }}
                    className="absolute right-0 top-2 inset-y-0 mr-3 text-lg"
                    onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)}
                  />
                ) : (
                  <BsEyeSlash
                    style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2', marginTop: '10px' }}
                    className="absolute right-0 top-2 inset-y-0 mr-3 text-lg"
                    onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)}
                  />
                )}
              </div>
              {errorData?.confirmPassword && (
                <div className="error-message">
                  <p>{errorData.confirmPassword}</p>
                </div>
              )}
              <div className="mt-6 flex justify-center">
                <button
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                    color: isCustomTemplate ? theme.c7 : '#fff',
                  }}
                  className="mt-10 px-4 rounded focus:outline-none"
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(ChangePassword)
