import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const CancelSubscriptionModal = ({ onCancel, cancelCallback, theme }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-90"
      >
        <div className={`relative w-11/12  xl:w-4/12 md:w-2/3 my-2 mx-auto`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#151c26',
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              borderColor: isCustomTemplate ? theme.c5 : '#fff',
            }}
            className="shadow-lg relative flex flex-col md:w-full p-5 border"
          >
            <div
              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
              className="flex flex-col justify-start"
            >
              <h4 className=" text-lg md:text-xl  font-semibold">{t('cancel_plan')}</h4>
              <p className="">{t(`once_subscription_cancel`)}</p>
            </div>
            <div className="mt-2 flex justify-center md:text-lg font-bold">
              <button
                style={{ backgroundColor: 'rgb(220 38 38)' }}
                className="mx-2 md:mt-10 xl:mt-12 px-4 py-1 text-white rounded focus:outline-none"
                onClick={onCancel}
              >
                {t('no')}
              </button>
              <button
                style={{
                  backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                  color: isCustomTemplate ? theme.c7 : '#fff',
                }}
                className="mx-2 md:mt-10 xl:mt-12 px-4 py-1 rounded focus:outline-none"
                onClick={cancelCallback}
              >
                {t('yes')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(CancelSubscriptionModal)
