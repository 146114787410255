import React, { useState } from 'react'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const ExtensiveCarouselRendition = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const { customNavigate } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
    return (
      <button
        className={` absolute left-[3vw] top-[45%] z-10  border-0 bg-transparent px-0 hidden lg:block`}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        title="Previous Item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <path
            d="m9 6 11.928 11.928L9 29.856"
            transform="rotate(180 11.171 15.282)"
            style={{
              fill: 'none',
              stroke: theme.c4,
              strokeWidth: 2,
            }}
          />
        </svg>
      </button>
    )
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
    return (
      <button
        className={` absolute right-[2.25vw] top-[45%] z-10 border-0 bg-transparent px-0 hidden lg:block`}
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        title="Next Item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <path
            d="m9 6 11.928 11.928L9 29.856"
            transform="translate(-8.293 -5.293)"
            style={{
              fill: 'none',
              stroke: theme.c4,
              strokeWidth: 2,
            }}
          />
        </svg>
      </button>
    )
  }

  return (
    <div
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c1, color: theme.c4 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="2xl:py-14 xl:py-10 py-8">
        <h2
          style={{ color: theme.c2 }}
          className="xl:text-3xl md:text-xl text-xs font-bold sm:mb-[2vw] mb-6 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[10px]"
        >
          {data?.heading}
        </h2>
        <div className="w-full text-[#fefefe]">
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            wrapperClassName="w-full md:mt-4"
          >
            {data?.slide_data &&
              data?.slide_data.map((info, index) => (
                <Card
                  key={index}
                  itemId={`index${index}`}
                  index={index}
                  info={info}
                  handleRedirect={handleRedirect}
                  theme={theme}
                />
              ))}
          </ScrollMenu>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

function Card({ index, info, handleRedirect, theme }) {
  const { getTemplateImage, SmallLiveImage } = useTemplateHook()
  return (
    <div
      key={index}
      className={`${
        !index && 'ml-2 2xl:ml-32 xl:ml-24 md:ml-16 sm:ml-10'
      } 2xl:w-[305px] lg:w-[265px] md:w-[200px] sm:w-[170px] w-[139px] 2xl:h-[277px] lg:h-[240px] md:h-[190px] sm:h-[154px] h-[132px] sm:mr-[2.25vw] mr-6`}
      style={{
        color: theme.c4,
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => handleRedirect(info?.link)}
    >
      <CustomImage
        imageUrl={getTemplateImage(info?.image_s3key)}
        imgClass="z-0 w-full h-auto rounded-lg border"
        loaderClass="z-0 w-full h-2/3"
        loaderStyle={{
          background: '#5A5A5A50',
        }}
        imgAlt={'class img"'}
      />
      <img
        src={SmallLiveImage}
        alt="live-icon"
        className="md:w-9 w-5"
        style={{ position: 'absolute', top: '5%', left: '5%' }}
      />
      <p
        className="font-semibold w-full 2xl:text-lg xl:text-base md:text-sm text-[10px] mt-[4px]"
        style={{ marginBottom: '0', color: theme.c3 }}
      >
        {info?.heading}
      </p>
      <p
        className="2xl:text-base xl:text-sm md:text-xs text-[10px] font-normal line-clamp-2"
        style={{ marginBottom: '0' }}
      >
        {info?.sub_heading}
      </p>
    </div>
  )
}

export default withTheme(ExtensiveCarouselRendition)
