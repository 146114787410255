import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/app'
import { isEmpty } from '../../helper'
import ReactHtmlParser from 'react-html-parser'
import { withTheme } from 'theming'

const CommonStaticPage = ({ theme }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { staticPages, staticPageRoutes, isCustomTemplate } = useContext(AppContext)
  const [pageData, setPageData] = useState({})

  useEffect(() => {
    window.scroll(0, 0)
    if (!isEmpty(staticPages)) {
      renderData()
    }
  }, [location.pathname, staticPages])

  const [searchParams] = useSearchParams()

  const renderData = () => {
    for (let index = 0; index < staticPages.length; index++) {
      const item = staticPages[index]
      if (`/${staticPageRoutes[item.type]}` === location.pathname) {
        setPageData(item)
        break
      }
      if (index === staticPages.length - 1) {
        navigate('/home')
        break
      }
    }
  }

  let appViewStyle = searchParams.get('mobileApp') !== 'true' ? 'mb-15 mt-4' : 'mt-8'
  return (
    <>
      <div className={`bg-no-repeat bg-right bg-contain ${appViewStyle}`}>
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2' }}
          className="md:mt-8 px-8 md:px-20"
        >
          <h4
            style={{ color: isCustomTemplate ? theme.c3 : '#e3e2e2' }}
            className="md:text-3xl text-xl font-semibold mb-2"
          >
            {pageData?.title}
          </h4>
          {!isEmpty(pageData) && ReactHtmlParser(pageData.content)}
        </div>
      </div>
    </>
  )
}

export default withTheme(CommonStaticPage)
