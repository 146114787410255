import React, { useState, useEffect, useContext } from 'react'
import Player from '../player/player'
import { useParams } from 'react-router'
import { CONTENT_WATCH_URL, DETAIL_CONTENT } from '../../apiUrls'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'

const IFrameVideo = () => {
  const { setLoader } = useContext(AppContext)
  const [detailObj, setDetailObj] = useState()
  const [resumeTime, setResumeTime] = useState(0)
  const contentId = useParams().contentId

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      console.log(contentWatchUrl)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setLoader(contentLoading || contentWatchUrlLoading)
  }, [contentLoading || contentWatchUrlLoading])

  useEffect(() => {
    contentDetailCall()
  }, [contentId])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setDetailObj(contentObj)
      get_video_url()
    }
  }, [contentDetail])

  const get_video_url = () => {
    getContentWatchUrl()
  }

  return (
    <div>
      {contentWatchUrl && contentWatchUrl.success && (
        <Player
          contentInfo={detailObj}
          handleClose={() => {
            setResumeTime(0)
          }}
          contentWatchUrl={
            detailObj?.video_type === 'LIVE'
              ? detailObj?.stream.link
              : contentWatchUrl?.data.signed_url
          }
          resumeTime={resumeTime}
        />
      )}
    </div>
  )
}

export default IFrameVideo
