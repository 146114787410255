import React from 'react'
import VideoPlaceholder from '../../assets/video-content.png'
import CustomImage from '../customImage/customImage'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'

const ContentCard = ({ data, theme, type, isEdit = false }) => {
  // const navigate = useNavigate()
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const handleClick = () => {
    if (isEdit) return
    if (data.video_type === 'PLAYLIST') {
      customNavigate('DIRECT', `/playlist/${data.content_id}`)
      return
    }
    if (data.video_type === 'MPVP') {
      customNavigate('DIRECT', `/parent-content/${data.content_id}`)
    } else {
      customNavigate('DIRECT', `/content/${data.content_id}`)
    }
  }

  return (
    <div
      className="relative rounded-lg overflow-hidden shadow-xl transform transition duration-500 ease z-0"
      onClick={handleClick}
    >
      <div className="bg-black flex justify-center">
        <CustomImage
          imageUrl={getTemplateImage(data?.thumbnail)}
          imgClass="w-full h-full object-center"
          loaderClass={'h-20 md:h-32'}
          imgAlt={data.title}
          errorPlaceholder={VideoPlaceholder}
        />
      </div>
      <div
        style={{ color: theme.c4 }}
        // style={{ color: '#fff' }}
        className="px-[1rem] py-[0.5rem] shadow-2xl"
      >
        <div className="mb-[0.5rem] flex justify-between items-center ">
          <p className="text-xs md:text-base font-bold line-clamp-1 ">
            {type === 'content' ? data.title : data.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ContentCard)
