import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'

const MiamiFoxFooter = ({ componentData, modifyData, setModalState, isEdit, onSwap, onDelete }) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const {
    customNavigate,
    getTemplateImage,
    FBLogo,
    InstaLogo,
    TGLogo,
    YTLogo,
    TwitterLogo,
    BitchuteLogo,
    RumbleLogo,
    BoxcastLogo,
    TikTokLogo,
    EmailIcon,
    BasicPlaceholder,
    PhoneIcon,
    getExternalLink,
    DMCALogo,
  } = useTemplateHook()

  //   const [searchParams] = useSearchParams()
  const location = useLocation()
  const [isWordpressPages, setIsWordpressPages] = useState(false)

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  useEffect(() => {
    if (location) {
      if (['/be-a-guest', '/be-a-host', '/be-the-change'].includes(location.pathname)) {
        setIsWordpressPages(true)
      } else {
        setIsWordpressPages(false)
      }
    }
  }, [location])

  //   const mobileApp = searchParams.get('mobileApp') === 'true'

  const socialIcons = {
    facebook: FBLogo,
    twitter: TwitterLogo,
    instagram: InstaLogo,
    telegram: TGLogo,
    youtube: YTLogo,
    bitchute: BitchuteLogo,
    tiktok: TikTokLogo,
    rumble: RumbleLogo,
    boxcast: BoxcastLogo,
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <>
      {/* {!mobileApp && ( */}
      <footer
        className={
          isWordpressPages
            ? `footer-1  pb-4`
            : `footer-1 mt-8 md:mt-12 lg:mt-20 xl:mt-16 2xl:mt-32  pb-4 ${
                isEdit && hoverState && 'component-hover-class'
              }`
        }
        style={{ backgroundColor: '#31383c' }}
        onMouseOver={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onDelete={() => onDelete(id)}
            onEdit={() => handleModal()}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <div
          className="lg:pl-[2.7%] lg:pr-[3%] py-7  mx-auto  relative mb-8  "
          style={{
            borderBottom: '2px solid #5fc1f2',
          }}
        >
          <p
            className=" absolute sm:-bottom-[45px] -bottom-8 left-0 text-center text-white w-full sm:text-sm text-xs lg:text-base 2xl:text-lg"
            style={{ fontFamily: 'sans-serif' }}
          >
            © {data?.copyright_text}
          </p>
          <div className="flex lg:flex-row flex-col items-center lg:items-stretch justify-around">
            <div className="flex justify-end lg:pb-0 pb-9">
              <div>
                <img
                  src={getTemplateImage(data?.image_s3key)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = BasicPlaceholder
                  }}
                  alt="logo"
                  className="2xl:w-[140px] 2xl:h-[61px] xl:w-28 xl:h-12 lg:w-20 lg:h-9 w-[78px] h-[34px] cursor-pointer"
                  onClick={() => customNavigate('DIRECT', '/home', isEdit)}
                />
              </div>
            </div>
            <div
              className="text-white lg:grid gap-x-16 lg:pb-0 pb-6"
              style={{
                width: '55%',
                gridAutoFlow: 'column',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(4, max-content)',
                // gridColumnGap: '50px',
                gridRowGap: '0px',
              }}
            >
              {data?.column_one_menus.map((page, index) => (
                <div className={'lg:text-left text-center'} key={index}>
                  <p
                    className="lg:px-6 cursor-pointer sm:text-sm text-xs lg:text-base 2xl:text-lg h-full sm:mb-0 mb-3 lg:w-fit"
                    style={{ fontFamily: 'sans-serif' }}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
              {data?.column_two_menus.map((page, index) => (
                <div className={'lg:text-left text-center'} key={index}>
                  <p
                    className="lg:px-6 cursor-pointer sm:text-sm text-xs lg:text-base 2xl:text-lg h-full sm:mb-0 mb-3 lg:w-fit"
                    style={{ fontFamily: 'sans-serif' }}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
            <div className={`text-white lg:text-left ${!isEdit && 'text-center'}`}>
              {data?.button?.enabled && (
                <button
                  className=" lg:px-4 px-4 xl:py-2 lg:py-1 py-2 mb-8 sm:text-sm text-xs lg:text-base 2xl:text-lg border-0"
                  style={{
                    outline: 'none',
                    backgroundImage:
                      'linear-gradient(454deg, rgb(4, 96, 133) -17%, rgb(95, 193, 242) 53%, rgb(4, 96, 133) 108%)',
                    borderRadius: '6px',
                    fontWeight: '700',
                    fontFamily: 'sans-serif',
                    color: '#1b242f',
                  }}
                  onClick={() => handleRedirect(data.button?.link)}
                >
                  {data.button?.button_text}
                </button>
              )}

              <p
                style={{ fontFamily: 'sans-serif' }}
                className="mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg"
              >
                {data?.header}
              </p>
              {data?.email && (
                <p className="flex mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg justify-center lg:justify-start">
                  <a
                    href={`mailto:${data?.email}`}
                    title={`mailto:${data?.email}`}
                    rel="noopener noreferrer"
                    className="flex text-white text-decoration-none"
                  >
                    <img src={EmailIcon} alt="" className="mr-2" />
                    <span style={{ fontFamily: 'sans-serif' }} className="mx-1">
                      {data?.email}
                    </span>
                  </a>
                </p>
              )}

              {data?.phone && (
                <p className="flex mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg justify-center lg:justify-start">
                  <a
                    href={`tel:${data?.phone}`}
                    title={`tel:${data?.phone}`}
                    rel="noopener noreferrer"
                    className="flex text-white text-decoration-none"
                  >
                    <img src={PhoneIcon} alt="" className="mr-2" />
                    <span style={{ fontFamily: 'sans-serif' }} className="mx-1">
                      {data?.phone}
                    </span>
                  </a>
                </p>
              )}

              <div className="flex lg:justify-start justify-center items-center">
                {data?.social_handles.map((item, index) => (
                  <div className=" md:w-6 w-4 ml-6 first:ml-0 mt-1 inline-block" key={index}>
                    <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                      <img className="w-full" src={socialIcons[item.name]} alt={`${item.name}`} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-end mt-4 sm:mt-0">
              <div className=" md:w-20 w-14  ">
                <img className="w-full h-auto" src={DMCALogo} alt={`DMCALogo`} />
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* )} */}
    </>
  )
}

export default MiamiFoxFooter
