import React, { useContext, useEffect, useState } from 'react'
import PlaylistPlayer from '../../../container/player/playlistPlayer'
import { ContentContext } from '../../../context/content'
import { SubscriptionContext } from '../../../context/subscription'
import { AppContext } from '../../../context/app'
import useAxios from 'axios-hooks'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import { CONTENT_WATCH_URL, DETAIL_CONTENT } from '../../../apiUrls'
import LiveCounter from '../../../container/contentDetail/liveCounter'
import { useInterval } from '../../../hooks/useInterval'
import LockIcon from '../../../assets/lock-icon.svg'

const BasicContentPlayer = ({ data, controlBar }) => {
  const contentId = data.id
  const { isLogin, setLoader, setOpenLoginModal, setModalType } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const { setIsVideoPlaying } = useContext(ContentContext)

  const [playingVideo, setPlayingVideo] = useState(false)

  const [detailObj, setDetailObj] = useState()
  const [isLive, setIsLive] = useState(false)
  const [endTime, setEndTime] = useState(null)
  const [isContentSubscribed, setIsContentSubscribed] = useState(true)

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: contentWatchUrl }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (!playingVideo) {
      contentDetailCall()
    }
  }, [playingVideo, contentId])

  useEffect(async () => {
    if (!isEmpty(detailObj) || (detailObj?.video_type === 'LIVE' && isLive)) {
      if (await checkRestriction()) {
        get_video_url(detailObj)
      }
    }
  }, [detailObj, isLive, isLogin, activeSubscription])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data

      setDetailObj(contentObj)
      if (contentObj.video_type.includes('LIVE')) {
        setEndTime(new Date(contentObj.live_expiry_time + 'Z').getTime())
        if (
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            contentObj.video_type === 'VOD_LIVE') ||
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            new Date(contentObj.live_expiry_time + 'Z').getTime() > Date.now())
        ) {
          setIsLive(true)
        }
      }
    }
  }, [contentDetail])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setLoader(isEmpty(detailObj) && contentLoading)
  }, [contentLoading])

  const checkPlanExist = (contentActiveSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, activeSubscription, isEqual))
  }

  const get_video_url = (detailObj) => {
    if (detailObj.video_type === 'LIVE') {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    } else {
      getContentWatchUrl()
    }
  }

  const checkRestriction = async (openModal = false) => {
    if (detailObj.video_type === 'LIVE' && endTime < Date.now()) {
      return false
    }

    if (!detailObj.is_restricted) {
      if (detailObj.is_login_required && !isLogin) {
        setIsContentSubscribed(false)
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        return false
      }
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsContentSubscribed(false)
        return false
      } else {
        let contentSub = [
          ...detailObj.active_subscription.map((item) => item.id),
          ...detailObj.active_ppv_subscription.map((item) => item.id),
        ]

        const planExist = checkPlanExist(contentSub, activeSubscription)

        if (!planExist) {
          setIsContentSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(contentSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const onCounterComplete = () => {
    setIsLive(true)
  }

  useInterval(
    async () => {
      if (endTime) {
        const time = Date.now()

        if (time >= endTime) {
          setIsLive(false)
          setPlayingVideo(false)
          setIsVideoPlaying(false)
        }
      }
    },
    endTime && Date.now() < endTime ? 1000 : null,
  )

  return (
    <div className="w-full h-full">
      {detailObj && (
        <>
          {playingVideo ? (
            <PlaylistPlayer
              contentInfo={detailObj}
              contentWatchUrl={
                detailObj.video_type === 'LIVE'
                  ? detailObj.stream.link
                  : contentWatchUrl.data.signed_url
              }
              resumeTime={'0'}
              handlePlayingSequence={() => {}}
              disableHeader={true}
              loop={true}
              controlBar={controlBar}
            />
          ) : (
            <div className="border w-full h-full bg-black">
              {detailObj.video_type.includes('LIVE') &&
                !isLive &&
                (detailObj.video_type === 'VOD_LIVE' || endTime > Date.now()) && (
                  <div className="flex relative justify-center w-full h-full">
                    <LiveCounter
                      styles={{ top: '32%' }}
                      counterClass={' w-4/5 lg:w-fit'}
                      contentDetail={detailObj}
                      handleCompletion={onCounterComplete}
                    />
                  </div>
                )}
              {detailObj.video_type === 'LIVE' && endTime < Date.now() && (
                <div className="w-full h-full flex justify-center items-center ">
                  <button
                    style={{ background: '#09e9e9', color: 'whitesmoke' }}
                    className="w-fit text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg py-1 px-3"
                    disabled={true}
                  >
                    Stream Ended
                  </button>
                </div>
              )}

              {!isContentSubscribed && (
                <div className="w-full h-full flex justify-center items-center ">
                  <div className=" w-4/5 md:w-fit">
                    <div className="flex px-5 md:px-0">
                      <img
                        src={LockIcon}
                        style={{
                          width: '18.5px',
                          height: '24.6px',
                        }}
                        alt="LockIcon"
                      />
                      <p
                        style={{ color: '#cecece' }}
                        className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                      >
                        This Content is not included in your current Membership Plan.
                      </p>
                    </div>
                    <button
                      style={{ background: '#09e9e9', color: '#000' }}
                      className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                      onClick={() => checkRestriction(true)}
                    >
                      Upgrade your Membership Plan to Watch Now
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BasicContentPlayer
