import React, { useContext, useEffect, useState } from 'react'
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi'
import VideoJS from '../../components/player/videoJS'
import LiveIcon from '../../assets/live_icon_final.gif'
import { isEmpty } from 'lodash'
import { getTrailerUrl } from '../../helper'
import { AppContext } from '../../context/app'

const TrailerPlayer = ({
  height = '',
  poster,
  preview,
  quality,
  redirect,
  type,
  stream,
  videoType,
}) => {
  const playerRef = React.useRef(null)
  const [toggleMute, setToggleMute] = useState(true)
  const [showMute, setShowMute] = useState(false)
  const { accountObj } = useContext(AppContext)

  useEffect(() => {
    if (!isEmpty(preview)) {
      changeSource(preview, poster)
    }
  }, [preview, poster])

  const videoJsOptions = {
    // lookup the options in the docs for more options
    // autoplay: true,
    controls: false,
    responsive: true,
    muted: toggleMute,
    loadingSpinner: true,
    autoplay: videoType === 'LIVE' && !isEmpty(preview),
    liveui: true,
    poster: poster,
    // preload: "metadata",
    // fluid: true,
    // aspectRatio: "16:9",
    // fill: true,
    suppressNotSupportedError: true,
    sources: [
      preview && {
        src: getTrailerUrl(accountObj?.cf_domain_name, preview, quality),
        type: 'application/x-mpegURL',
      },
    ],
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      // console.log('player is waiting')
    })

    player.on('canplay', () => {
      // console.log('player is canplay')

      const startVideo = async () => {
        try {
          if (!playerRef.current) {
            return
          }
          await playerRef.current.play()

          // playerRef.current.setAttribute("autoplay", true);
          setShowMute(true)
          // console.log('video started playing successfully')
        } catch (err) {
          // console.log(err, 'video play error')
          // do stuff in case your video is unavailable to play/autoplay
        }
      }

      setTimeout(startVideo, 1000)
    })

    player.on('dispose', () => {
      // console.log('player will dispose')
    })
  }

  const changeSource = (preview, poster) => {
    if (!playerRef.current) {
      return
    }
    setShowMute(false)
    setToggleMute(true)
    playerRef.current.muted(true)
    playerRef.current.autoplay(true)
    playerRef.current.poster(poster)
    playerRef.current.src([
      preview && {
        src: getTrailerUrl(accountObj?.cf_domain_name, preview, quality),
        type: 'application/x-mpegURL',
      },
    ])
  }

  const changePlayerOptions = (status) => {
    // you can update the player through the Video.js player instance
    if (preview && !playerRef.current) {
      return
    }
    if (status === 'Redirect' && type === 'HOVER') {
      redirect()
      return
    }
    // [update player through instance's api]
    playerRef.current.muted(status)
    setToggleMute(status)
  }

  return (
    <div className="relative">
      {showMute && (
        <div
          className="flex justify-end absolute right-0 p-4 z-10 text-white"
          style={{ alignItems: 'center' }}
        >
          {videoType === 'LIVE' && (
            <div
              style={{
                display: 'flex',
                marginRight: '15px',
                alignItems: 'center',
              }}
            >
              <img src={LiveIcon} alt="live icon" style={{ width: '30px', height: '30px' }} />
            </div>
          )}
          {!isEmpty(preview) && (
            <>
              {toggleMute ? (
                <BiVolumeMute
                  className={type === 'CONTENT' ? 'w-8 h-8' : ''}
                  onClick={() => changePlayerOptions(false)}
                />
              ) : (
                <BiVolumeFull
                  className={type === 'CONTENT' ? 'w-8 h-8' : ''}
                  onClick={() => changePlayerOptions(true)}
                />
              )}
            </>
          )}
        </div>
      )}
      <div
        onClick={() => changePlayerOptions('Redirect')}
        style={{ height: `${height}` }}
        className="trailer-player-div"
      >
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} cover={true} />
      </div>
    </div>
  )
}

export default TrailerPlayer
