import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from '../../helper'
import { useParams } from 'react-router'
import useAxios from 'axios-hooks'
import { CAPTURE_PAYMENT } from '../../apiUrls'
import { AppContext } from '../../context/app'
import { IoIosCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const PaymentStatus = ({ theme }) => {
  const data = useParams().data
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [redirectionUrl, setRedirectionUrl] = useState('/')
  const [statusUI, setStatusUI] = useState(
    <div style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className="text-2xl ">
      Loading Please Wait ...
    </div>,
  )
  const [timer, setTimer] = useState(10)
  const { t } = useTranslation()

  const [
    { data: capturePaymentRes, loading: capturePaymentLoading, error: capturePaymentError },
    capturePaymentCall,
  ] = useAxios(
    {
      url: CAPTURE_PAYMENT,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(capturePaymentLoading)
  }, [capturePaymentLoading])

  useEffect(() => {
    if (capturePaymentRes) {
      if (capturePaymentRes?.success) {
        setRedirectionUrl(capturePaymentRes?.data.redirection_url)
        setStatusUI(
          <div
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className="text-base font-semibold md:text-2xl flex flex-col items-center"
          >
            <IoIosCheckmarkCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-green-500" />
            <p className="mt-2">{t('payment_successful')}</p>
          </div>,
        )
      } else {
        setStatusUI(
          <div
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className="text-base font-semibold md:text-2xl flex flex-col items-center"
          >
            <IoMdCloseCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-red-600 " />
            <p className="mt-2">{t('try_again')} !</p>
          </div>,
        )
      }
    }

    if (capturePaymentError && capturePaymentError.response.status !== 200) {
      setStatusUI(
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className="text-base font-semibold md:text-2xl flex flex-col items-center"
        >
          <IoMdCloseCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-red-600 " />
          <p className="mt-2">{t('try_again')} !</p>
        </div>,
      )
    }
  }, [capturePaymentRes, capturePaymentError])

  useEffect(() => {
    if (!isEmpty(data)) {
      const payload = JSON.parse(atob(data))
      capturePaymentCall({
        data: payload,
      })
    }
  }, [data])

  useEffect(() => {
    if (!capturePaymentLoading) {
      let myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1)
        }
        if (timer === 0) {
          clearInterval(myInterval)
          window.location.replace(redirectionUrl)
        }
      }, 1000)

      return () => {
        clearInterval(myInterval)
      }
    }
  }, [timer, capturePaymentLoading])

  return (
    <div style={{ minHeight: '70vh' }} className="flex flex-col items-center justify-center">
      {statusUI}
      {!capturePaymentLoading && (
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className=" md:text-2xl flex flex-col items-center justify-center mt-2 md:mt-10"
        >
          <p className="">
            {t('redirected_in')} {timer}...
          </p>
          <a
            style={{
              background: 'green',
              color: isCustomTemplate ? theme.c7 : '#000',
            }}
            className="border rounded-lg px-3 mt-2"
            href={redirectionUrl}
          >
            {t('ok')}
          </a>
        </div>
      )}
    </div>
  )
}

export default withTheme(PaymentStatus)
