import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { RESET_PASSWORD, RESET_PASSWORD_KEY_VALIDATION } from '../../apiUrls'
import { raiseToast } from '../../helper'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'theming'

const ResetPassword = ({ theme }) => {
  const { setLoader, setOpenLoginModal, isLogin, isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  const resetKey = useParams().resetKey
  const [password, setPassword] = useState('')
  const [cnfPassword, setCnfPassword] = useState('')
  const navigate = useNavigate()
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (isLogin) {
      navigate(`/`)
    }
  }, [isLogin])

  const [{ data: resetPasswordKeyResp, loading: resetPasswordKeyLoading }] = useAxios({
    url: RESET_PASSWORD_KEY_VALIDATION,
    method: 'post',
    data: {
      reset_key: resetKey,
    },
  })

  const [{ data: resetPasswordResp, loading: resetPasswordLoading }, resetPasswordCall] = useAxios(
    {
      url: RESET_PASSWORD,
      method: 'put',
    },
    {
      manual: true,
    },
  )

  const handleSave = async () => {
    if (isEmpty(password) || password !== cnfPassword) {
      raiseToast('error', 'Please make sure password and confirm password should be same')
    } else {
      resetPasswordCall({
        data: {
          password: password,
          reset_key: resetKey,
        },
      })
    }
  }

  useEffect(() => {
    if (resetPasswordKeyResp) {
      if (resetPasswordKeyResp.success) {
        setIsValid(true)
      } else {
        raiseToast('error', resetPasswordKeyResp.message)
      }
    }
  }, [resetPasswordKeyResp])

  useEffect(() => {
    if (resetPasswordResp && resetPasswordResp.success) {
      raiseToast('success', resetPasswordResp.message)
      navigate('/')
      setOpenLoginModal(true)
    }
  }, [resetPasswordResp])

  useEffect(() => {
    setLoader(resetPasswordKeyLoading || resetPasswordLoading)
  }, [resetPasswordKeyLoading, resetPasswordLoading])

  const handleChange = (event, func) => {
    const { value } = event.target
    func(value)
  }
  return (
    <div className="md:mb-0 mb-12 flex justify-center mt-12">
      <div
        style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
        className="md:my-5 my-2  xl:w-5/12 w-8/12"
      >
        <div className="flex justify-center">
          <p className="md:text-2xl text-lg font-bold mt-10">{t('reset_password')}</p>
        </div>
        <div className="py-2 md:text-xl">
          <div>
            <p>{`${t('password')} *`}</p>
            <input
              type="text"
              name="name"
              value={password}
              disabled={!isValid}
              onChange={(e) => handleChange(e, setPassword)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className=" border lg:text-lg rounded block w-full py-1 p-2.5 pl-2 focus:outline-none"
            />
          </div>
          <div className="mt-6">
            <p>{`${t('confirm_password')} *`}</p>
            <div
              //   style={{ border: "2px solid white" }}
              className="relative"
            >
              <input
                type="password"
                name="password"
                value={cnfPassword}
                disabled={!isValid}
                onChange={(e) => handleChange(e, setCnfPassword)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className=" border lg:text-lg rounded block w-full py-1 p-2.5 pl-2"
              />
            </div>
          </div>
          <div className="flex justify-center md:mt-10 mt-8 ">
            <button
              className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white px-6 py-1 rounded-full focus:outline-none"
              onClick={handleSave}
            >
              {t('reset_password')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ResetPassword)
