import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'
import useAxios from 'axios-hooks'
import { GET_COMMENTS_API, POST_LIKE_URL } from '../../apiUrls'
import InComponentLoader from '../InComponentLoader'
import { isEmpty } from 'lodash'
import CommentComponent from './comment'
import CommentInput from './commentInput'
import { raiseToast } from '../../helper'

const CommentListing = (props) => {
  const { type, id, theme } = props
  const { isLogin, setOpenLoginModal, isCustomTemplate } = useContext(AppContext)
  const [comments, setComments] = useState([])

  const [{ data: commentsList, loading: commentsListLoading }, getLiveComments] = useAxios(
    {
      url: GET_COMMENTS_API + '/' + type + '/' + id,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: postComment, loading: postCommentLoading }, postCommentCall] = useAxios(
    {
      url: GET_COMMENTS_API + '/' + type + '/' + id,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: likeComments, loading: likeCommentLoading }, likeCommentCall] = useAxios(
    {
      url: POST_LIKE_URL + '/comment/',
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (commentsList && commentsList.success) {
      setComments(commentsList.data)
    }
  }, [commentsList])

  useEffect(() => {
    if (postComment && postComment.success) {
      if (postComment.data.parent_id) {
        setComments((comments) =>
          comments.map((comment) => {
            if (comment._id === postComment.data.parent_id)
              return { ...comment, replies: [postComment.data.comment, ...comment.replies] }
            else {
              return comment
            }
          }),
        )
      } else {
        setComments((comments) => [postComment.data.comment, ...comments])
      }
    }
  }, [postComment])

  useEffect(() => {
    if (likeComments && likeComments.success) {
      setComments((comments) =>
        comments.map((comment) => {
          if (comment._id === likeComments.data.obj_id)
            if (likeComments.data.like) {
              return { ...comment, like_count: comment.like_count + 1 }
            } else {
              return { ...comment, dislike_count: comment.dislike_count + 1 }
            }
          else {
            return comment
          }
        }),
      )
    }
  }, [likeComments])

  useEffect(() => {
    getLiveComments()
  }, [])

  const handleSubmit = (inputText) => {
    if (!isLogin) {
      setOpenLoginModal(true)
      return
    }
    if (inputText) {
      postCommentCall({
        data: {
          comment: inputText,
        },
      })
    } else {
      raiseToast('error', 'Please provide the comment')
    }
  }

  const handleLikeDislike = (id, like) => {
    likeCommentCall({
      url: POST_LIKE_URL + '/comment/' + id + '?isLike=' + like,
      method: 'post',
    })
  }

  const handleUpdateChildLike = (likeCommentObj, parentId) => {
    setComments((comments) =>
      comments.map((comment) => {
        if (comment._id === parentId) {
          return {
            ...comment,
            replies: comment.replies.map((reply) => {
              if (reply._id === likeCommentObj.obj_id) {
                if (likeCommentObj.like) {
                  return { ...reply, like_count: reply.like_count + 1 }
                } else {
                  return { ...reply, dislike_count: reply.dislike_count + 1 }
                }
              } else {
                return reply
              }
            }),
          }
        } else {
          return comment
        }
      }),
    )
  }

  const handleSubmitReply = (inputText, parentId) => {
    if (!isLogin) {
      setOpenLoginModal(true)
      return
    }
    if (inputText) {
      postCommentCall({
        data: {
          comment: inputText,
          parent_id: parentId,
        },
      })
    } else {
      raiseToast('error', 'Please provide the comment')
    }
  }

  return (
    <div>
      <InComponentLoader
        loading={commentsListLoading || postCommentLoading || likeCommentLoading}
      />
      <div className="py-1">
        <div className={'py-4'}>
          <CommentInput theme={theme} handleSubmit={handleSubmit} clearInput={postCommentLoading} />
        </div>
        <div className="comments-lister">
          {!isEmpty(comments) &&
            comments.map((comment) => (
              <CommentComponent
                key={comment._id}
                comment={comment}
                theme={theme}
                handleLikeDislike={handleLikeDislike}
                handleSubmitReply={handleSubmitReply}
                handleUpdateChildLike={handleUpdateChildLike}
              />
            ))}
          {isEmpty(comments) && !commentsListLoading && (
            <p
              className={'text-md md:text-md font-bold mt-5 '}
              style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
            >
              No comments yet !!
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTheme(CommentListing)
