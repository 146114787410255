const XAVIER_URL = process.env.REACT_APP_XAVIER_URL
const RAVEN_URL = process.env.REACT_APP_RAVEN_URL
const TRUBEL_URL = process.env.REACT_APP_TRUBEL_URL
const TYRION_URL = process.env.REACT_APP_TYRION_URL

const TEMPLATE_URL = XAVIER_URL + '/api/v1/template'
const WATCHLIST_URL = XAVIER_URL + '/api/v1/watchlist'
const FAVOURITE_URL = XAVIER_URL + '/api/v1/like/content'
const CONTENT_FILTERING = XAVIER_URL + '/api/v1/content/filter'
const CONTENT_FILTERING_V2 = XAVIER_URL + '/api/v2/content/filter'
const CONTENT_SEARCH = XAVIER_URL + '/api/v1/content/new-search'
const RELATED_CONTENT = XAVIER_URL + '/api/v1/content/related/'
const DETAIL_CONTENT = XAVIER_URL + '/api/v1/content/detail/'
const CONTENT_WATCH_URL = XAVIER_URL + '/api/v1/content/video_url/'
const PARENT_DETAIL_CONTENT = XAVIER_URL + '/api/v1/content/parent-detail/'
const GET_CATEGORY = XAVIER_URL + '/api/v1/content/meta-data/system'
const ARTIST_LISTING = XAVIER_URL + '/api/v1/artist'
const VENUE_LISTING = XAVIER_URL + '/api/v1/venue'
// const ANALYTICS_API = XAVIER_URL + '/api/v1/analytics/event'
const GET_REVIEWS_API = XAVIER_URL + '/api/v1/review'
const GET_COMMENTS_API = XAVIER_URL + '/api/v1/comments'
const POST_LIKE_URL = XAVIER_URL + '/api/v1/like'
const IPTV_CHANNEL_FILTER = XAVIER_URL + '/api/v2/content/iptv-channel-filter'
const IPTV_CHANNEL_SCHEDULE = XAVIER_URL + '/api/v1/content/iptv-channel/schedule'
const RELATED_CHANNEL = XAVIER_URL + '/api/v1/content/related-channel/'
const CUSTOM_FORM = XAVIER_URL + '/api/v1/custom-forms'

const LIVE_COMMENTS_API = XAVIER_URL + '/api/v1/comments/content'

const LOGIN_API = RAVEN_URL + '/api/v1/user/login'
const SIGNUP_API = RAVEN_URL + '/api/v1/user/subscriber/signup'
const LOGOUT_API = RAVEN_URL + '/api/v1/user/logout'
const VALIDATE_SESSION = RAVEN_URL + '/api/v1/user/validate-session'
const CHANGE_PASSWORD = RAVEN_URL + '/api/v1/user/change-password'
const SEND_VERIFY_EMAIL = RAVEN_URL + '/api/v1/user/send-verify-email'
const VERIFY_EMAIL = RAVEN_URL + '/api/v1/user/verify-email'
const UPDATE_USER_INFO = RAVEN_URL + '/api/v1/user/update-info'
const STATIC_PAGE = RAVEN_URL + '/api/v1/config/static-pages'
const ACCOUNT_API = RAVEN_URL + '/api/v1/account/detail'
const FORGOT_PASSWORD = RAVEN_URL + '/api/v1/user/forgot-password'
const RESET_PASSWORD_KEY_VALIDATION = RAVEN_URL + '/api/v1/user/check-reset-password-key'
const RESET_PASSWORD = RAVEN_URL + '/api/v1/user/reset-password'
const TEMPLATE_V2_URL = RAVEN_URL + '/api/v2/template'
const CUSTOM_PAGES_API = RAVEN_URL + '/api/v1/custom-page'

const GET_ACTIVE_SUBSCRIPTIONS = TYRION_URL + '/api/v1/payment/active-subscription'
const GET_SUBSCRIPTIONS = TYRION_URL + '/api/v1/subscription'
const PAYEMENT_ORGS = TYRION_URL + '/api/v1/payment-gateway/customer-org'
const GET_DISCOUNTS = TYRION_URL + '/api/v1/payment/discount'
const GET_ESTIMATE = TYRION_URL + '/api/v1/payment/get-estimation'
const CREATE_TRANSACTION = TYRION_URL + '/api/v1/payment/create-transaction'
const CAPTURE_PAYMENT = TYRION_URL + '/api/v1/payment/capture-payment'
const GET_TRANSACTION = TYRION_URL + '/api/v1/payment/get-transactions'
const GET_RENEWAL_SUBSCRIPTION = TYRION_URL + '/api/v1/payment/renewal-subscriptions'

const ANALYTICS_API = TRUBEL_URL + '/api/v1/extract/event'
const RESUME_TIMESTAMP_API = TRUBEL_URL + '/api/v1/load/resume-timestamp'

export {
  TEMPLATE_URL,
  WATCHLIST_URL,
  FAVOURITE_URL,
  CONTENT_FILTERING,
  CONTENT_SEARCH,
  LOGIN_API,
  SIGNUP_API,
  LOGOUT_API,
  VALIDATE_SESSION,
  STATIC_PAGE,
  ACCOUNT_API,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS,
  PAYEMENT_ORGS,
  GET_DISCOUNTS,
  GET_ESTIMATE,
  CREATE_TRANSACTION,
  CAPTURE_PAYMENT,
  GET_TRANSACTION,
  RELATED_CONTENT,
  DETAIL_CONTENT,
  GET_CATEGORY,
  CHANGE_PASSWORD,
  UPDATE_USER_INFO,
  VENUE_LISTING,
  ANALYTICS_API,
  ARTIST_LISTING,
  PARENT_DETAIL_CONTENT,
  CONTENT_WATCH_URL,
  GET_RENEWAL_SUBSCRIPTION,
  GET_REVIEWS_API,
  LIVE_COMMENTS_API,
  RESET_PASSWORD_KEY_VALIDATION,
  RESET_PASSWORD,
  SEND_VERIFY_EMAIL,
  VERIFY_EMAIL,
  FORGOT_PASSWORD,
  TEMPLATE_V2_URL,
  CONTENT_FILTERING_V2,
  CUSTOM_PAGES_API,
  GET_COMMENTS_API,
  POST_LIKE_URL,
  IPTV_CHANNEL_FILTER,
  IPTV_CHANNEL_SCHEDULE,
  RELATED_CHANNEL,
  CUSTOM_FORM,
  RESUME_TIMESTAMP_API,
}
