import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import './subscriptionStyle.scss'
import { SubscriptionContext } from '../../context/subscription'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const SubscriptionSlide = (props) => {
  const { t } = useTranslation()
  const { slide, offset, theme } = props
  let navigate = useNavigate()
  const { setOpenSubscriptionModal, activeSubscription } = useContext(SubscriptionContext)
  const { isCustomTemplate } = useContext(AppContext)
  const handleSubscribe = () => {
    setOpenSubscriptionModal(false)
    navigate(`/payment/${slide._id}`)
  }

  const isSubscribed = activeSubscription.includes(slide._id)
  return (
    <div
      className="slide-sub"
      style={{
        '--offset': offset,
        '--dir': offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div className="slideBackground" />
      <div className="slideContent">
        {offset === 0 ? (
          <div
            style={{
              backgroundColor: isCustomTemplate ? theme.c1 : '#30527b',
              height: '20rem',
              width: '100%',
              overflow: 'hidden',
              borderColor: isCustomTemplate ? theme.c3 : '',
            }}
            className="relative h-96 border rounded-xl duration-700"
          >
            {/*<div*/}
            {/*  style={{*/}
            {/*    background: '#f5db2f',*/}
            {/*    color: 'red',*/}
            {/*    transform: 'rotate(45deg)',*/}
            {/*  }}*/}
            {/*  className="w-32 h-32 absolute right-0 -m-16 align-text-bottom"*/}
            {/*>*/}
            {/*  <p className="">Black Friday</p>*/}
            {/*  <p className="">OFFER</p>*/}
            {/*  <p className="">OFFER</p>*/}
            {/*  <p className="">a</p>*/}
            {/*  <p className="ml-8 mt-2">SALE $10</p>*/}
            {/*</div>*/}
            <div
              style={{
                backgroundColor: isCustomTemplate ? theme.c5 : '#3ea3c9',
                color: isCustomTemplate ? theme.c7 : '#fff',
              }}
              className="px-4 py-9 rounded-xl text-center "
            >
              <p className="text-2xl">
                <b>{slide.amount.toFixed(2)}</b>
                <sup className="text-sm">{slide.currency}</sup>
              </p>
              <p className="text-base">{slide.name}</p>
            </div>
            <div className="text-center mt-4">
              <p style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2' }} className="text-sm">
                {slide.duration} {t('days')}
              </p>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2' }}
                className="text-sm px-3 line-clamp-3"
              >
                {slide.description}
              </p>
              <button
                style={{ background: '#1976d2' }}
                className="mt-5 text-white px-4 py-1 rounded focus:outline-none"
                onClick={handleSubscribe}
                disabled={isSubscribed}
              >
                {isSubscribed ? `${t('already_subscribed')}` : `${t('subscribe_now')}`}
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: isCustomTemplate ? theme.c1 : '#223347',
              borderColor: isCustomTemplate ? theme.c3 : '',
            }}
            className="h-64 mt-8 text-white border rounded-xl duration-500"
          >
            <div
              style={{ backgroundColor: isCustomTemplate ? theme.c6 : '#344d6b' }}
              className="px-4 py-6 rounded-xl text-center"
            >
              <p className="text-xl py-2">
                <b>{slide.amount.toFixed(2)}</b>
                <sup className="text-sm">{slide.currency}</sup>
              </p>
              <p className="text-sm">{slide.name}</p>
            </div>
            <div className="text-center mt-4">
              <p style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2' }} className="text-sm">
                {slide.duration} {t('days')}
              </p>
              <p style={{ color: isCustomTemplate ? theme.c4 : '#e3e2e2' }} className="text-sm">
                {slide.description}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withTheme(SubscriptionSlide)
