import React, { useEffect, useState, useContext } from 'react'
import Accordion from './Accordion/Accordion'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/app'
import ReactHtmlParser from 'react-html-parser'
import { isEmpty } from '../../helper'
import { withTheme } from 'theming'
const Faq = ({ theme }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { staticPages, isCustomTemplate } = useContext(AppContext)
  const [content, setContent] = useState([])

  useEffect(() => {
    window.scroll(0, 0)
    if (!isEmpty(staticPages)) {
      renderData()
    }
  }, [staticPages])

  const renderData = () => {
    const faqObj = staticPages.find((obj) => obj.type === 'FAQ')

    if (faqObj && faqObj?.visible_on_website) {
      setContent(faqObj.content)
    } else {
      navigate(`/home`)
    }
  }

  let appViewStyle = searchParams.get('mobileApp') !== 'true' ? 'mt-4' : ''

  return (
    <div className="bg-no-repeat bg-right-top">
      <div className={`md:ml-16 md:mt-8 xl:w-1/2 px-6 ${appViewStyle}`}>
        <h4
          style={{ color: isCustomTemplate ? theme.c3 : '#e3e2e2' }}
          className="md:text-3xl text-xl font-semibold md:mb-6"
        >
          FAQs
        </h4>
        {content.map((info, index) => (
          <Accordion title={info.question} content={ReactHtmlParser(info.answer)} key={index} />
        ))}
      </div>
    </div>
  )
}

export default withTheme(Faq)
