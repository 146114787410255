import React from 'react'
import imageSpinner from '../../assets/image-spinner.gif'

const InComponentLoader = ({ loading, color = 'rgba(60, 60, 60, 0.6)' }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full flex items-center justify-center z-99 ${
        loading ? 'visible' : 'hidden'
      }`}
      style={{ backgroundColor: color }}
    >
      <img src={imageSpinner} alt="spinner" style={{ width: '2rem' }} />
    </div>
  )
}

export default InComponentLoader
