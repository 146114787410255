import React, { useContext, useEffect, useState } from 'react'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import { AppContext } from '../../../context/app'
import { SubscriptionContext } from '../../../context/subscription'
import useAxios from 'axios-hooks'
import LockIcon from '../../../assets/lock-icon.svg'
import { useInterval } from '../../../hooks/useInterval'
import moment from 'moment'
import {
  CONTENT_FILTERING_V2,
  CONTENT_WATCH_URL,
  IPTV_CHANNEL_FILTER,
  IPTV_CHANNEL_SCHEDULE,
} from '../../../apiUrls'
import { convertToLocalTime, getImage } from '../../../helper'
import ChannelPlayer from '../../../container/player/channelPlayer'

const TemplateIPTVPlayer = ({ data, controlBar }) => {
  const channelId = data.id

  const { isLogin, setLoader, setOpenLoginModal, setModalType, accountObj } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const [detailObj, setDetailObj] = useState({})
  const [isChannelSubscribed, setIsChannelSubscribed] = useState(true)
  const [currentPlayingContent, setCurrentPlayingContent] = useState({})
  const [currentPlayingEndTime, setCurrentPlayingEndTime] = useState(null)
  const [nextStartTime, setNextStartTime] = useState(null)
  const [playerQueue, setPlayerQueue] = useState([])
  const [videoSource, setVideoSource] = useState(null)
  const [scheduleData, setScheduleData] = useState([])

  const [{ data: channelDetails, loading: channelDetailsLoading }, channelDetailsCall] = useAxios(
    {
      url: IPTV_CHANNEL_FILTER,
      method: 'post',
    },
    {
      manual: true,
    },
  )

  const [{ data: channelSchedule, loading: channelScheduleLoading }, channelScheduleCall] =
    useAxios(
      {
        url: IPTV_CHANNEL_SCHEDULE,
        method: 'get',
      },
      {
        manual: true,
      },
    )

  const [{ loading: scheduleContentLoading }, scheduleContentCall] = useAxios(
    {
      url: CONTENT_FILTERING_V2,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: contentWatchUrl }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(channelDetailsLoading || channelScheduleLoading || scheduleContentLoading)
  }, [channelDetailsLoading, channelScheduleLoading, scheduleContentLoading])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setVideoSource(contentWatchUrl?.data?.signed_url)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setVideoSource(null)
    setCurrentPlayingContent({})
    channelDetailsCall({
      data: {
        filters: { channel_ids: [channelId] },
        page: '1',
      },
    })

    channelScheduleCall({
      params: {
        channel_id: channelId,
        start_time: moment().subtract(7, 'd').startOf('day').toISOString(),
        end_time: moment().endOf('day').toISOString(),
      },
    })
  }, [channelId])

  useEffect(() => {
    if (channelDetails && channelDetails.success) {
      setDetailObj(channelDetails.data[0])
    }
  }, [channelDetails])

  useEffect(async () => {
    if (channelSchedule && channelSchedule.success) {
      let tempSchduleArr = []
      let contentIDs = channelSchedule?.data.map((info) => info?.content_id)
      let contents = await scheduleContentCall({
        data: {
          filters: {
            content_ids: contentIDs,
          },
          page: 1,
          page_size: contentIDs.length,
        },
      })

      for (let i = 0; i < channelSchedule?.data.length; i++) {
        const scheduledContent = channelSchedule?.data[i]
        let contentObj = contents.data?.data.find(
          (content) => content.content_id === scheduledContent?.content_id,
        )

        if (contentObj) {
          tempSchduleArr.push({
            ...scheduledContent,
            contentDetail: contentObj,
          })
        }
      }
      console.log('tempSchduleArr', tempSchduleArr)
      setScheduleData([...tempSchduleArr])
    }
  }, [channelSchedule, isLogin])

  useEffect(async () => {
    if (!isEmpty(detailObj) && !isEmpty(scheduleData)) {
      if (await checkRestriction()) {
        let todaysContents = scheduleData.filter((ele) => {
          return (
            moment(new Date(ele.start_time + 'Z')).format('DD-MM-YYYY') ===
              moment().format('DD-MM-YYYY') ||
            moment(new Date(ele.end_time + 'Z')).format('DD-MM-YYYY') ===
              moment().format('DD-MM-YYYY')
          )
        })

        let tempPlayerQueue = []

        for (let i = 0; i < todaysContents.length; i++) {
          const content = todaysContents[i]
          const startTime = moment(convertToLocalTime(content.start_time), 'HH:mm')
          const endTime = moment(convertToLocalTime(content.end_time), 'HH:mm')
          const time = moment(new Date(), 'HH:mm')

          if (time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime)) {
            setCurrentPlayingContent(content.contentDetail)
            setCurrentPlayingEndTime(convertToLocalTime(content.end_time))
            continue
          }

          if (time.isBefore(startTime)) {
            tempPlayerQueue.push(content)
            continue
          }
        }

        setPlayerQueue([
          ...tempPlayerQueue.sort((first, second) => first.start_time - second.start_time),
        ])
      }
    }
  }, [detailObj, scheduleData, isLogin, activeSubscription])

  useInterval(async () => {
    if (currentPlayingEndTime) {
      const time = moment(new Date(), 'HH:mm').format('HH:mm')

      if (time === currentPlayingEndTime) {
        setVideoSource(null)
        setCurrentPlayingContent({})
        setCurrentPlayingEndTime(null)
      }
    }
  }, 1000)

  useInterval(async () => {
    if (nextStartTime) {
      const time = moment(new Date(), 'HH:mm').format('HH:mm')
      if (time === nextStartTime) {
        setCurrentPlayingContent(playerQueue[0].contentDetail)
        setCurrentPlayingEndTime(convertToLocalTime(playerQueue[0].end_time))
        setNextStartTime(
          playerQueue.length > 2 ? convertToLocalTime(playerQueue[1].start_time) : null,
        )
        let tempQueue = playerQueue.filter((e, idx) => idx !== 0)
        setPlayerQueue([...tempQueue])
      }
    }
  }, 1000)

  useEffect(async () => {
    if (!isEmpty(playerQueue)) {
      setNextStartTime(convertToLocalTime(playerQueue[0].start_time))
    }
  }, [playerQueue])

  useEffect(async () => {
    if (!isEmpty(currentPlayingContent)) {
      if (currentPlayingContent.is_live && !isEmpty(currentPlayingContent.stream)) {
        setVideoSource(currentPlayingContent.stream.link)
        return
      }
      getContentWatchUrl({ url: `${CONTENT_WATCH_URL}${currentPlayingContent.content_id}` })
    }
  }, [currentPlayingContent])

  const checkPlanExist = (contentActiveSubscription, userSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, userSubscription, isEqual))
  }

  const checkRestriction = async (openModal = false) => {
    if (!detailObj.is_restricted) {
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsChannelSubscribed(false)
        return false
      } else {
        let channelSub = [
          ...detailObj?.active_subscription.map((item) => item._id),
          ...detailObj?.active_ppv.map((item) => item._id),
        ]
        const planExist = checkPlanExist(channelSub, activeSubscription)

        if (!planExist) {
          setIsChannelSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(channelSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const getResumeTimestamp = () => {
    const currentScheduledContent = scheduleData.filter(
      (info) =>
        info.content_id === currentPlayingContent.content_id &&
        moment(new Date(info?.end_time + 'Z')).format('DD-MM-YYYY HH:mm') ===
          moment(moment(new Date()).format('MM-DD-YYYY') + ' ' + currentPlayingEndTime).format(
            'DD-MM-YYYY HH:mm',
          ),
    )

    if (currentScheduledContent.length === 1) {
      const content = currentScheduledContent[0]

      let duration = moment.duration(
        moment(new Date(), 'HH:mm').diff(moment(convertToLocalTime(content.start_time), 'HH:mm')),
      )
      return duration.asSeconds()
    }

    return 0
  }

  return (
    <div className="w-full h-full">
      {videoSource && (
        <ChannelPlayer
          contentInfo={currentPlayingContent}
          contentWatchUrl={videoSource}
          resumeTime={getResumeTimestamp()}
          watermarkLogo={detailObj?.logo?.website?.s3_key}
          controlBar={controlBar}
          // handlePlayingSequence={handlePlayingSequence}
        />
      )}
      {!videoSource ? (
        !isChannelSubscribed ? (
          <div className="border w-full h-full bg-black  flex justify-center items-center">
            <div className=" w-[70%] md:w-fit">
              <div className="flex px-5 md:px-0">
                <img
                  src={LockIcon}
                  style={{
                    width: '18.5px',
                    height: '24.6px',
                  }}
                  alt="LockIcon"
                />
                <p
                  style={{ color: '#cecece' }}
                  className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                >
                  This Channel is not included in your current Membership Plan.
                </p>
              </div>
              <button
                style={{ background: '#09e9e9', color: '#000' }}
                className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                onClick={() => checkRestriction(true)}
              >
                Upgrade your Membership Plan to Watch Now
              </button>
            </div>
          </div>
        ) : (
          <div className="border w-full h-full bg-black  flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <img
                src={getImage(accountObj?.cf_domain_name, detailObj?.logo?.website?.s3_key)}
                alt="channelimg"
                className={`   h-6 w-9 md:h-12 md:w-20 `}
              />
              <p
                style={{ color: '#cecece' }}
                className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
              >
                No Programs are shown at the moment.
              </p>
            </div>
          </div>
        )
      ) : null}
    </div>
  )
}

export default TemplateIPTVPlayer
