import React, { useContext, useState } from 'react'
import { AppContext } from '../../context/app'
import './contentDetail.css'
import useAxios from 'axios-hooks'
import { isEmpty } from 'lodash'
import { RELATED_CONTENT } from '../../apiUrls'
import { withTheme } from 'theming'
import ContentCardHover from '../../components/contentCard/contentCardHover'
import ContentCard from '../../components/contentCard/contentCard'
import InComponentLoader from '../../components/InComponentLoader'
import { useNavigate } from 'react-router-dom'
import { createSlug } from '../../helper'

const RelatedVideos = ({ theme, contentId }) => {
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const { isBrowser, isCustomTemplate } = useContext(AppContext)
  let navigate = useNavigate()

  const [{ data: relatedVideos, loading: relatedVideosLoading }] = useAxios({
    url: `${RELATED_CONTENT}${contentId}`,
    method: 'post',
    data: { filters: {} },
  })

  return (
    <>
      <InComponentLoader loading={relatedVideosLoading} />

      <div className="py-10 lg:py-10 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-x-[43px]">
        {relatedVideos &&
          !isEmpty(relatedVideos?.data) &&
          relatedVideos.data.slice(0, 8).map((content, index) =>
            hoverCardIndex === index && isBrowser ? (
              <div className="relative" onMouseLeave={() => setHoverCardIndex('')} key={index}>
                <ContentCardHover data={content} type={'content'} />
              </div>
            ) : (
              <div onMouseOver={() => setHoverCardIndex(index)} key={index}>
                <ContentCard data={content} type={'content'} />
              </div>
            ),
          )}
        {relatedVideos && isEmpty(relatedVideos.data) && !relatedVideosLoading && (
          <p
            className={'text-md md:text-md font-bold mt-5 '}
            style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
          >
            No related videos available
          </p>
        )}
      </div>
      {relatedVideos && !isEmpty(relatedVideos.data) && relatedVideos.data.length > 8 && (
        <div className={'flex justify-center mt-5'}>
          <button
            className="px-4 py-1 ml-2 md:text-sm"
            style={{
              color: '#fff',
              backgroundColor: '#1976d2',
              borderRadius: '6px',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              const encoded = btoa(JSON.stringify(relatedVideos.filters))
              navigate(`/content/filter/${createSlug('Related videos')}?filters=${encoded}`)
            }}
          >
            {`View More Related videos`}
          </button>
        </div>
      )}
    </>
  )
}

export default withTheme(RelatedVideos)
