import React, { useContext, useState } from 'react'
import VideoJS from '../../player/videoJS'
import { AppContext } from '../../../context/app'
import { getTrailerUrl } from '../../../helper'
import MuteIcon from '../../../assets/mute-icon.svg'
import SoundIcon from '../../../assets/sound-icon.svg'
import FullscreenIcon from '../../../assets/fullscreen-icon.svg'

const TemplateTrailerPlayer = ({ data, poster, quality, controlBar = true }) => {
  const playerRef = React.useRef(null)
  const { accountObj } = useContext(AppContext)
  const [toggleMute, setToggleMute] = useState(true)

  const videoJsOptions = {
    autoplay: true,
    muted: toggleMute,
    responsive: true,
    loadingSpinner: true,
    liveui: true,
    fill: true,
    loop: true,
    poster: poster,
    suppressNotSupportedError: true,
    sources: [
      {
        src: getTrailerUrl(accountObj?.cf_domain_name, data?.id, quality),
        type: 'application/x-mpegURL',
      },
    ],
    plugins: {
      qualityLevels: {},
      hlsQualitySelector: {},
    },
    html5: {
      hls: {
        overrideNative: true,
      },
    },
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      // console.log('player is waiting')
    })

    player.on('canplay', () => {})

    player.on('dispose', () => {
      // console.log('player will dispose')
    })
  }

  const changePlayerOptions = (status) => {
    // you can update the player through the Video.js player instance
    if (!playerRef.current) {
      return
    }

    // [update player through instance's api]
    playerRef.current.muted(status)
    setToggleMute(status)
  }
  const fullScreenToggle = () => {
    if (!playerRef.current) {
      return
    }
    playerRef.current.requestFullscreen()
  }

  return (
    <div className="w-full h-full relative">
      {controlBar && (
        <div
          className="flex justify-end absolute right-0 bottom-0 m-4 z-10 text-white"
          style={{ alignItems: 'center' }}
        >
          {toggleMute ? (
            <img
              src={MuteIcon}
              className={'w-8 h-8 cursor-pointer mx-2'}
              alt="MuteIcon"
              style={{ opacity: '75%' }}
              onClick={() => changePlayerOptions(false)}
            />
          ) : (
            <img
              src={SoundIcon}
              className={'w-8 h-8 cursor-pointer mx-2'}
              alt="SoundIcon"
              style={{ opacity: '75%' }}
              onClick={() => changePlayerOptions(true)}
            />
          )}
          <img
            src={FullscreenIcon}
            className={'w-8 h-8 cursor-pointer mx-2'}
            style={{ opacity: '75%' }}
            alt="FullscreenIcon"
            onClick={fullScreenToggle}
          />
        </div>
      )}

      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} cover={true} />
    </div>
  )
}

export default TemplateTrailerPlayer
