import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { LOGIN_API } from '../../apiUrls'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'theming'

const Login = ({ theme }) => {
  const { t } = useTranslation()
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const [errorData, setErrorData] = useState({})

  const { setLoader, setOpenForgotPasswordModal, setUser, isCustomTemplate } =
    useContext(AppContext)
  const navigate = useNavigate()

  const handleForgotPassword = () => {
    setOpenForgotPasswordModal(true)
  }

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data)
      navigate(`/`)
    }
  }, [loginRes])

  useEffect(() => {
    setLoader(loginResLoading)
  }, [loginResLoading])

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}
    if (loginEmail.trim().length === 0) {
      isFormValid = false
      temp.loginEmail = `${t('email_empty')}!`
    }

    if (loginPassword.trim().length === 0) {
      isFormValid = false
      temp.loginPassword = `${t('password_empty')}!`
    }
    setErrorData({ ...temp })
    return isFormValid
  }

  const handleLogin = async () => {
    if (validateInfo('login')) {
      loginCall({
        data: {
          email_id: loginEmail,
          password: loginPassword,
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  return (
    <>
      <div
        style={{
          color: isCustomTemplate ? theme.c4 : '#fff',
        }}
        className="flex flex-col items-center "
      >
        <div
          style={{
            backgroundColor: isCustomTemplate ? theme.c10 : '',
            borderColor: isCustomTemplate ? theme.c4 : '',
          }}
          className="w-4/5 md:w-2/4 mt-10 border p-5 rounded-xl"
        >
          <div className="md:text-2xl text-lg font-semibold flex justify-center">
            <h2>{t('sign_in')}</h2>
          </div>
          <div className="relative mt-2 py-6  md:px-20 flex-auto">
            <input
              type="email"
              name="loginEmail"
              value={loginEmail}
              onChange={(e) => handleChange(e, setLoginEmail)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className=" border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={t('email')}
            />
            {errorData?.loginEmail && (
              <div className="error-message">
                <p>{errorData.loginEmail}</p>
              </div>
            )}

            <input
              type="password"
              name="loginPassword"
              value={loginPassword}
              onChange={(e) => handleChange(e, setLoginPassword)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={t('password')}
            />
            {errorData?.loginPassword && (
              <div className="error-message">
                <p>{errorData.loginPassword}</p>
              </div>
            )}
            <div className="mt-4 flex justify-between">
              <div className="flex items-center">
                <div className="flex items-center">
                  <input
                    id="remember"
                    type="checkbox"
                    defaultChecked={true}
                    className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                </div>
                <div className=" ml-2">
                  <label
                    htmlFor="remember"
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className=" text-sm md:text-lg font-medium "
                  >
                    {t('remember_me')}
                  </label>
                </div>
              </div>
            </div>
            <div className={'flex justify-center'}>
              <button
                style={{
                  backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                  color: isCustomTemplate ? theme.c7 : '#fff',
                }}
                className="mt-10 ml-2 px-6  py-2 rounded focus:outline-none"
                onClick={handleLogin}
              >
                {t('sign_in')}
              </button>
            </div>
          </div>

          {/*footer*/}
          <div className="flex items-center justify-center p-6 text-sm md:text-lg xl:text-xl">
            <p
              style={{
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className=""
            >
              {t('not_registered')}
            </p>
            <p
              style={{ color: isCustomTemplate ? theme.c2 : '#3686d6', cursor: 'pointer' }}
              className="ml-2 hover:underline"
              onClick={() => {
                navigate('/signup')
              }}
            >
              {t('register')}
            </p>
          </div>
          <div className={'flex justify-center'}>
            <p
              style={{ color: isCustomTemplate ? theme.c2 : '#3686d6', cursor: 'pointer' }}
              className="ml-2 hover:underline"
              onClick={handleForgotPassword}
            >
              {'Forgot Password ?'}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(Login)
