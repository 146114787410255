import React, { useEffect, useRef, useState } from 'react'
import { withTheme } from 'theming'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyle'
import EditMenu from '../editMenu'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isEmpty } from 'lodash'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const InventorySlide = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage, VideoPlaceholder } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  useEffect(() => {
    setSetting({
      dots: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <></>,
      prevArrow: <></>,
      pauseOnHover: true,
      adaptiveHeight: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              borderRadius: '0px',
              padding: '0px',
            }}
          >
            <ul>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${info.props.className === 'slick-active' ? theme.c3 : ''}`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="" />
        </div>
      ),
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full relative pb-10 xl:pb-20  ${
        isEdit && hoverState && 'component-hover-class'
      }`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="flex flex-col items-center md:py-5 md:px-4 px-6">
        {data?.title && (
          <h1
            style={{ color: theme.c2 }}
            className={` uppercase m-1 text-lg md:text-3xl xl:text-4xl 2xl:text-5xl md:py-5 font-bold sm:mb-5 mb-2`}
          >
            {data?.title}
          </h1>
        )}

        <SliderWrapper className="w-full flex justify-center md:mt-10">
          <Slider
            ref={sliderRef}
            {...setting}
            className="md:w-[83%] w-full"
            style={{
              height: `${
                window.matchMedia('(min-width: 768px)').matches
                  ? `${(100 * 0.83) / 2.87}vw`
                  : 'max-content'
              }`,
            }}
          >
            {data?.slide_data &&
              data?.slide_data.map((info, index) => (
                <div key={index} className="w-full h-full">
                  <div
                    className={`rounded-md w-full h-full flex md:flex-row flex-col justify-between  `}
                    style={{
                      border: '1px solid #707070',
                      background: `linear-gradient(to left,${theme.c1}, ${theme.c10})`,
                    }}
                  >
                    <div
                      className="xl:w-1/2 w-full md:px-5 sm:py-4 px-[0.75rem] py-[0.5rem] flex-shrink-0"
                      style={{
                        height: `${
                          window.matchMedia('(min-width: 768px)').matches
                            ? `100%`
                            : `${(100 * 0.83) / 1.49}vw`
                        }`,
                      }}
                    >
                      <div
                        className="h-full rounded-md overflow-hidden relative"
                        style={{ border: '0.5px solid #707070' }}
                      >
                        <CustomImage
                          imageUrl={getTemplateImage(info?.image_s3key)}
                          imgClass="w-full h-full"
                          loaderClass="w-full"
                          loaderStyle={{
                            background: '#5A5A5A50',
                            height: `${
                              window.matchMedia('(min-width: 768px)').matches
                                ? `${(100 * 0.75) / 2.87}vw`
                                : `${(100 * 0.83) / 1.49}vw`
                            }`,
                          }}
                          imgAlt={'category'}
                          errorPlaceholder={VideoPlaceholder}
                        />
                        {/* <span
                      style={{
                        background: theme.c3,
                        clipPath: `polygon(0 0, 100% 0, 76% 100%, 0 100%)`,
                      }}
                      className={`absolute left-0 top-0 text-white md:pt-3 pt-3 pl-3 pr-10 md:pb-4 pb-3 md:text-2xl text-[10px]`}
                    >
                      120+ Videos
                    </span> */}
                        <div className="absolute top-[45%] md:px-8 px-6">
                          <h1
                            style={{
                              color: theme.c3,
                              textShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
                            }}
                            className={`2xl:text-5xl lg:text-3xl md:text-xl text-lg font-bold`}
                          >
                            {info.header}
                          </h1>
                          <p
                            style={{
                              color: theme.c3,
                              textShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
                            }}
                            className={`2xl:text-xl md:text-base text-[10px]`}
                          >
                            {info.sub_header}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        maxHeight: `${
                          window.matchMedia('(min-width: 768px)').matches
                            ? `${(100 * 0.75) / 2.87}vw`
                            : `${(100 * 0.79) / 1.2}vw`
                        }`,
                      }}
                      className="xl:w-[48.2%] w-full p-3 overflow-auto"
                    >
                      {!isEmpty(info?.child_content) &&
                        info.child_content.map((childInfo, idx) => (
                          <div
                            className=" xl:mt-1 last:mb-0 mb-[1rem] md:mb-6 w-full flex cursor-pointer"
                            key={idx}
                            onClick={() => handleRedirect(childInfo?.link)}
                          >
                            <div className="h-full w-[27%] mr-3">
                              <CustomImage
                                imageUrl={getTemplateImage(childInfo.image_s3key)}
                                imgClass="w-full h-full"
                                loaderClass="w-full h-full"
                                loaderStyle={{
                                  background: '#5A5A5A50',
                                  height: `${(100 * 0.18) / 2.87}vw`,
                                }}
                                imgAlt={'small img'}
                                errorPlaceholder={VideoPlaceholder}
                              />
                            </div>
                            <div className="w-[70%]">
                              <h5
                                style={{ color: theme.c3, fontWeight: 500 }}
                                className={` md:text-lg 2xl:text-2xl text-xs lg:pb-2 pb-0`}
                              >
                                {childInfo.header}
                              </h5>
                              <pre
                                style={{ color: theme.c4, whiteSpace: 'pre-wrap' }}
                                className={`md:text-xs 2xl:text-sm text-[8px]`}
                              >
                                {childInfo.sub_header}
                              </pre>
                              {/* <p  > */}
                              {/* <span className="mr-4"></span> */}
                              {/* <span className="mr-4">|</span>
                                <span className="mr-4">Jul 19, 2022</span> */}
                              {/* </p> */}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </SliderWrapper>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(InventorySlide)
