import React, { useContext, useEffect, useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import { raiseToast } from '../../helper'
import { isEmpty } from 'lodash'
import useAxios from 'axios-hooks'
import { GET_REVIEWS_API } from '../../apiUrls'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'

const ReviewModal = (props) => {
  const { type, id, handleClose, title, onReviewed, alreadyReviewed, theme } = props
  const [reviewTitle, setReviewTitle] = useState('')
  const [reviewDesc, setReviewDesc] = useState('')
  const [reviewRating, setReviewRating] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const { setLoader, isCustomTemplate } = useContext(AppContext)

  useEffect(() => {
    if (!isEmpty(alreadyReviewed)) {
      setReviewTitle(alreadyReviewed.title)
      setReviewDesc(alreadyReviewed.description)
      setReviewRating(alreadyReviewed.rating)
      setIsEdit(true)
    }
  }, [alreadyReviewed])

  const [{ data: createReview, loading: createReviewLoading }, postReview] = useAxios(
    {
      url: GET_REVIEWS_API + '/' + type + '/' + id,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: updateReview, loading: updateReviewLoading }, updateReviewCall] = useAxios(
    {
      url: GET_REVIEWS_API + '?review_id=' + alreadyReviewed._id,
      method: 'put',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(createReviewLoading || updateReviewLoading)
  }, [createReviewLoading, updateReviewLoading])

  useEffect(() => {
    if (createReview && createReview.success) {
      raiseToast('success', 'Review submitted successfully')
      onReviewed()
      handleClose()
    }
  }, [createReview])

  useEffect(() => {
    if (updateReview && updateReview.success) {
      raiseToast('success', 'Review updated successfully')
      onReviewed()
      handleClose()
    }
  }, [updateReview])

  const onClickSubmit = () => {
    if (reviewRating < 0.5) {
      raiseToast('error', 'Please provide rating')
    } else if (!isEmpty(reviewDesc) && isEmpty(reviewTitle)) {
      raiseToast('error', 'Please provide review title')
    } else {
      if (isEdit) {
        updateReviewCall({
          data: {
            title: reviewTitle,
            description: reviewDesc,
            rating: reviewRating,
          },
        })
      } else {
        postReview({
          data: {
            title: reviewTitle,
            description: reviewDesc,
            rating: reviewRating,
          },
        })
      }
    }
  }

  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className={`relative w-11/12  xl:w-5/12 md:w-2/4 my-2 mx-auto`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#151c26',
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              border: '',
            }}
            className="shadow-lg relative flex flex-col md:w-full p-5 "
          >
            <div className=" py-2 object-right-top">
              <button
                className="p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={handleClose}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="  h-6 w-6 text-xl xl:text-2xl block outline-none focus:outline-none"
                >
                  ×
                </span>
              </button>
              <div className="flex flex-col justify-start">
                <h4
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" text-sm md:text-xl  font-semibold"
                >
                  {isEdit ? 'Edit your' : 'Write'}
                  {' review for ' + title}
                </h4>
              </div>
            </div>
            <div>
              <div className="mt-6">
                <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={'text-white'}>
                  {'Rating *'}
                </p>
                <ReactStars
                  count={5}
                  size={24}
                  activeColor={isCustomTemplate ? theme.c2 : '#ffd700'}
                  value={!isEmpty(alreadyReviewed) ? alreadyReviewed.rating : reviewRating}
                  edit={true}
                  onChange={(val) => setReviewRating(val)}
                  isHalf={true}
                />
              </div>

              <div className="mt-6">
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className={'text-white mb-2'}
                >{`Review heading`}</p>
                <input
                  type="text"
                  value={reviewTitle}
                  placeholder={'Please provide review heading'}
                  onChange={(e) => setReviewTitle(e.target.value)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="bg-gray-900 border border-gray-600 text-white lg:text-lg rounded block w-full
                  py-1 p-2.5 pl-2 focus:outline-none"
                />
              </div>

              <div className="mt-6">
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className={'text-white mb-2'}
                >{`Review description`}</p>
                <textarea
                  rows="3"
                  value={reviewDesc}
                  placeholder={'Please provide brief review'}
                  onChange={(e) => setReviewDesc(e.target.value)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className="bg-gray-900 border border-gray-600 text-white lg:text-lg rounded block w-full py-1 p-2.5 pl-2 focus:outline-none"
                />
              </div>
              <div className={'flex justify-end'}>
                <button
                  className="px-4 py-1 focus:outline-none ml-5 mt-10"
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                    color: isCustomTemplate ? theme.c7 : '#fff',
                    borderRadius: '6px',
                  }}
                  onClick={onClickSubmit}
                >
                  {'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(ReviewModal)
