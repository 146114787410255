/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { ACCOUNT_API, LOGOUT_API, VALIDATE_SESSION, STATIC_PAGE } from '../apiUrls'
import { isEmpty } from 'lodash'
import i18n from 'i18next'
import { LG_SIZE, SM_SIZE } from '../constants'

const initialState = {
  userObj: {},
  isLogin: false,
  activePlans: [],
  userId: '',
  isCustomTemplate: false,
}
//create context

export const AppContext = createContext(initialState)

//Provider Component

export const AppProvider = ({ children }) => {
  const [userObj, setUserObj] = useState({})
  const [accountObj, setAccountObj] = useState({})
  const [modulePermissions, setModulePermissions] = useState({})
  const [isLogin, setIsLogin] = useState(false)
  const [loader, setLoader] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false)
  const [languageModal, setLanguageModal] = useState(false)
  const [popupModalLink, setPopupModalLink] = useState('')
  const [popUpTitle, setPopUpTitle] = useState('')
  const [isAccountInactive, setIsAccountInactive] = useState(false)
  const [isCustomTemplate, setIsCustomTemplate] = useState(false)
  const [staticPages, setStaticPages] = useState([])
  const [modalType, setModalType] = useState('login')
  const [isBrowser, setIsBrowser] = useState(true)

  const language = localStorage.getItem('language')
  // const [state, dispatch] = useReducer(AppReducer, initialState);

  // const [isAccess, setAccess] = useState(false)

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setIsBrowser(true)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setIsBrowser(false)
    } else {
      setIsBrowser(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const staticPageRoutes = {
    ABOUT_US: 'about-us',
    TNC: 'term-of-use',
    PRIVACY_POLICY: 'privacy-policy',
    COPYRIGHT: 'copyright',
    FAQ: 'faq',
  }

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
      validateSessionCall()
    }
  }, [])

  const [{ data: staticRes }] = useAxios({
    url: STATIC_PAGE,
    method: 'get',
  })

  useEffect(() => {
    if (staticRes && staticRes.success) {
      const { data } = staticRes
      setStaticPages(
        data.filter((obj) => obj.visible_on_website === true && obj.type !== 'SOCIAL_LINKS'),
      )
    }
  }, [staticRes])

  const [{ data: accountData, loading: accountDataLoading }] = useAxios({
    url: ACCOUNT_API,
    method: 'get',
  })

  const [
    { data: validateSession, loading: validateSessionLoading, error: validateSessionError },
    validateSessionCall,
  ] = useAxios(
    {
      url: VALIDATE_SESSION,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: logout, loading: logoutLoading }, logoutCall] = useAxios(
    {
      url: LOGOUT_API,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [])

  useEffect(() => {
    if (accountData && accountData.success) {
      if (accountData?.data?.status) {
        if (!language && !isEmpty(accountData?.data?.allowed_languages)) {
          localStorage.setItem('language', accountData?.data?.default_language)
          i18n.changeLanguage(accountData?.data?.default_language)
        }
        localStorage.setItem('default_language', accountData?.data?.default_language)
        localStorage.setItem('date_format', accountData?.data?.date_format)
        setAccountObj(accountData?.data)
        setIsCustomTemplate(accountData?.data?.od_resource_allowances?.custom_template)
        setModulePermissions(accountData?.data?.module_permissions)
      } else {
        setIsAccountInactive(true)
      }
    }
  }, [accountData])

  useEffect(() => {
    if (logout && logout.success) {
      setUserObj({})
      setIsLogin(false)
      localStorage.removeItem('sessionId')
      window.location.reload()
    }
  }, [logout])

  useEffect(() => {
    setLoader(validateSessionLoading || logoutLoading || accountDataLoading)
  }, [validateSessionLoading, logoutLoading, accountDataLoading])

  useEffect(() => {
    if (validateSession && validateSession.success) {
      setUser(validateSession.data)
    }
  }, [validateSession])

  useEffect(() => {
    if (validateSessionError) {
      localStorage.removeItem('sessionId')
    }
  }, [validateSessionError])

  function setUser(data) {
    setUserObj(data.user)
    setIsLogin(true)
    localStorage.setItem('sessionId', data.user_session.session_id)
  }

  return (
    <AppContext.Provider
      value={{
        isLogin,
        userObj,
        setUser,
        accountObj,
        setAccountObj,
        setLoader,
        setOpenLoginModal,
        openLoginModal,
        setLanguageModal,
        languageModal,
        popupModalLink,
        setPopupModalLink,
        popUpTitle,
        setPopUpTitle,
        logoutCall,
        loader,
        staticPages,
        setStaticPages,
        staticPageRoutes,
        setModalType,
        modalType,
        modulePermissions,
        setOpenForgotPasswordModal,
        openForgotPasswordModal,
        isAccountInactive,
        isCustomTemplate,
        isBrowser,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
